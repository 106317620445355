import { Group } from "@converge-collective/common/models/Group";
import Info from "@mui/icons-material/Info";
import { Backdrop, Stack, SxProps, Typography } from "@mui/material";
import { ComponentProps } from "react";

// get the type of the Mui Typography variant prop
type TypographyVariant = ComponentProps<typeof Typography>["variant"];

/**
 * NOTE: users of this component should place it in a container with `position:
 * relative`
 */
export function ArchivedGroup({
  group,
  typographyVariant = "h5",
  sx,
}: {
  group: Group;
  typographyVariant?: TypographyVariant;
  sx?: SxProps;
}) {
  return (
    <Backdrop
      sx={{
        ...sx,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        position: "absolute",
      }}
      open
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <Info color="light" fontSize="medium" />
        <Typography variant={typographyVariant} color="white">
          The <b>{group.name}</b> group has been archived.
        </Typography>
      </Stack>
    </Backdrop>
  );
}
