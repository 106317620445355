export async function sha256Hex(str: string): Promise<string> {
  if (typeof crypto.subtle !== "undefined") {
    // Browser/Web Crypto API
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    return Array.from(new Uint8Array(hashBuffer))
      .map((b) => b.toString(16).padStart(2, "0"))
      .join(""); // 64-char hex
  } else {
    // Node.js Crypto API
    const { createHash } = await import("crypto");
    return createHash("sha256").update(str).digest("hex");
  }
}
