import { converters } from "~/lib/converter";
import { isBrowser } from "browser-or-node";
import { DocumentReference, doc, getDoc } from "firebase/firestore";
import {
  eventDetailRoute,
  groupPostDetailRoute,
  myTeamPostDetailRoute,
  postDetailRoute,
  courseSectionRoute,
} from "~/routes";
import { baseUrl } from "./config";

type PostType = "team" | "group" | "network" | "events" | "challengesV2";
export const clearHash = (): void => {
  if (isBrowser) window.location.hash = "";
};

export const setHash = (hash: string): void => {
  if (isBrowser) window.location.hash = hash;
};

// NOTE: this is ported from https://github.com/converge-collective/fn/blob/50a84e9c7f2c29b9a640882ccd542d920be38cc4/src/notifications.ts#L180-L186
//
// It's very unfortunate we can't share this kind of code between client and
// server.
//
// determine the url for the post. It could be a:
// - network feed post e.g. /billings/posts/123
// - team feed post e.g. /billings/my-team/posts/123
// - group feed post e.g. /billings/groups/my-group/posts/123
export const urlForPost = async (
  postRef: DocumentReference
): Promise<string | undefined> => {
  // 1. determine the network slug for the post
  //
  // postPath looks like:
  //
  // network post:
  // networkTypes/ORGANIZATION/networks/slnZw3a38nWLHb95GcLQ/posts/09580ce711d2bf0033a5b171569da39e91cd6613a2aa5497d663d59cb8c4b501
  //
  // team post:
  // networkTypes/ORGANIZATION/networks/slnZw3a38nWLHb95GcLQ/teams/JxKKaX41WWOSvtbbkOM6/posts/H3xTOm95WtYbFdUoN0gH
  //
  // group post:
  // networkTypes/ORGANIZATION/networks/slnZw3a38nWLHb95GcLQ/groups/074ccbe3-f0e1-49d0-8e24-8d0c1e458b2f/posts/UdSPBCIIBw65uwb8OoKT
  //
  // event log:
  // networkTypes/COLLECTIVE/networks/jYhv5BV7CpgREPJGXgA5/events/nSZaUq4czbsPBP4a6jL5/logs/gZFdHguyMmUvsDoDrJOB

  const postPath = postRef.path;
  const segments = postPath.split("/");
  const networkPath = segments.slice(0, 4).join("/");
  const networkDoc = (
    await getDoc(
      doc(postRef.firestore, networkPath).withConverter(converters.network.read)
    )
  ).data();

  if (!networkDoc) {
    console.warn("could not find network for post", postRef.path);
    return;
  }

  // 2. determine what type of post this is
  const sectionPath = segments.slice(0, 10).join("/");
  const postType = getPostType(segments[4]);
  const courseSection =
    segments[4] === "challengesV2"
      ? await getDoc(
          doc(postRef.firestore, sectionPath).withConverter(
            converters.challengeSection.read
          )
        )
      : null;
  const sectionData = courseSection?.data();
  switch (postType) {
    case "network":
      return postDetailRoute(networkDoc.slug, postRef.id);
    case "team":
      return myTeamPostDetailRoute(networkDoc.slug, postRef.id);
    case "group":
      const groupPath = segments.slice(0, 6).join("/");
      const groupDoc = (
        await getDoc(
          doc(postRef.firestore, groupPath).withConverter(converters.group.read)
        )
      ).data();
      if (!groupDoc) {
        console.warn("could not find group for post", postRef.path);
        return;
      }
      return groupPostDetailRoute(networkDoc.slug, groupDoc.slug, postRef.id);
    case "events":
      const eventPath = segments.slice(0, 6).join("/");
      const event = (
        await getDoc(
          doc(postRef.firestore, eventPath).withConverter(converters.event.read)
        )
      ).data();
      if (!event) {
        console.warn("could not find event for post", postRef.path);
        return;
      }

      return eventDetailRoute(networkDoc.slug, event.slug);
    case "challengesV2":
      return sectionData
        ? courseSectionRoute(
            networkDoc.slug,
            sectionData.challengeV2Id,
            sectionData.blockId,
            sectionData.slug,
            postRef.id
          )
        : "";

    default:
      return;
  }
};

export const withBaseUrl = (url: string): string =>
  new URL(url, baseUrl()).toString();

function getPostType(segment: string): PostType {
  switch (segment) {
    case "events":
      return "events";
    case "teams":
      return "team";
    case "groups":
      return "group";
    case "challengesV2":
      return "challengesV2";
    default:
      return "network";
  }
}
