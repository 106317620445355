import { Check } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  ButtonProps,
  Collapse,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { isEmpty } from "lodash";
import { ComponentProps, useState } from "react";
import { useIsMobile } from "~/hooks/mobile";
import { createAlert } from "~/lib/globalAlerts";

type LoadingButtonProps = ComponentProps<typeof LoadingButton>;

/** Wraps a mui LoadingButton and adds extra capabilities */
export function AsyncButton<C extends React.ElementType>(
  props: ButtonProps<C, { component?: C }> & {
    successMessage: () => string;
    // boolean indicates whether it was successful or not
    handleSubmit: () => Promise<boolean>;
    inlineSuccessMessage?: () => string;
  }
) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [inlineSuccessMessage, setInlineSuccessMessage] = useState<string>();

  const { successMessage, ...rest } = props;
  const isMobile = useIsMobile();

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      alignItems="center"
      gap={1}
      width={isMobile ? "100%" : "auto"}
    >
      <Collapse orientation="horizontal" in={!isEmpty(inlineSuccessMessage)}>
        <Typography
          sx={{
            textWrap: "nowrap",
            fontSize: isMobile ? "0.8rem" : "1rem",
            textAlign: isMobile ? "center" : "left",
          }}
        >
          {inlineSuccessMessage}
        </Typography>
      </Collapse>
      <LoadingButton
        fullWidth={isMobile}
        startIcon={props.startIcon || <Check />}
        loadingPosition="start"
        sx={{
          fontSize: isMobile ? "0.85rem" : "1rem",
          padding: isMobile ? "8px 12px" : "10px 16px",
          minWidth: isMobile ? "100%" : "auto",
        }}
        onClick={async () => {
          setIsLoading(true);
          setInlineSuccessMessage("");
          try {
            const isSuccess = await props.handleSubmit();
            if (isSuccess) {
              setErrorMessages([]);
              const msg = successMessage();
              if (msg?.length) {
                createAlert({
                  message: msg,
                  severity: "info",
                });
                if (props.inlineSuccessMessage) {
                  setInlineSuccessMessage(props.inlineSuccessMessage());
                }
              }
            } else {
              throw new Error("Failed to create");
            }
          } catch (error) {
            console.error(error);
            setErrorMessages([error.message]);
            createAlert({ message: error.message, severity: "error" });
          } finally {
            setIsLoading(false);
          }
        }}
        loading={isLoading}
        {...rest}
      />
    </Stack>
  );
}
