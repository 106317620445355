import { logEvent } from "firebase/analytics";
import ls from "localstorage-slim";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useAnalytics } from "reactfire";
import useSWR from "swr";
import { getDetails } from "use-places-autocomplete";

const OneDaySeconds = 60 * 60 * 24;

// we need to resolve the photos and store it on the original object, because
// when we cache the object, serialization won't properly serialize the "getUrl"
// function.
export type GooglePlaceWithPhotos = Omit<PlaceResult, "photos"> & {
  photoUrls?: string[];
};

// type PlacesService = google.maps.places.PlacesService;
type PlaceResult = google.maps.places.PlaceResult;
type Err = any;
type Return = {
  loading: boolean;
  googlePlace?: GooglePlaceWithPhotos;
  error?: Err;
};

const DefaultFields = [
  "name",
  "formatted_address",
  "address_component",
  "photos",
  "place_id",
  "geometry",
  "plus_code",
  "types",
];

export default function useGooglePlace(
  placeId?: string,
  fields?: string[]
): Return {
  const [loading, setLoading] = useState(true);
  const analytics = useAnalytics();

  const cacheKey = `${placeId}-${(fields || []).join("-")}`;

  const fetchPlaceDetails = async (
    placeId?: string
  ): Promise<PlaceResult | undefined> => {
    if (!placeId || isEmpty(placeId)) {
      const error = new Error(
        "useGooglePlace: Can't fetch google place with empty place id"
      );
      console.log(error);
      throw error;
    }

    // check the cache
    const cachedResult = ls.get<PlaceResult>(cacheKey);

    const logParams = { placeId, cacheKey, fromCache: !!cachedResult };
    logEvent(analytics, "fetch_google_place", logParams);
    // console.log("useGooglePlace fetchPlaceDetails", logParams);

    // use cached value if available to avoid incurring a Google Places API call
    if (cachedResult) {
      // console.log(`useGooglePlace ${placeId} using cached result`, {
      //   cachedResult,
      // });
      return cachedResult;
    }

    // if no cache, fetch from Google Places API
    setLoading(true);
    const placeRequest = {
      placeId,
      fields: fields || DefaultFields,
    };

    const result = await getDetails(placeRequest);
    setLoading(false);
    if (typeof result === "string") {
      console.error("unexpected places result", { result });
      return undefined;
    } else {
      const { photos } = result;
      const newResult: GooglePlaceWithPhotos = {
        ...result,
        photoUrls: photos?.map((p) => p.getUrl()),
      };
      // console.log("useGooglePlace fetched result", { result: newResult });
      ls.set(cacheKey, newResult, { ttl: OneDaySeconds });
      return newResult;
    }
  };

  // NOTE: SWR is re-fetching every time the user re-focuses the browser (that
  // can be turned off via settings). This is harmless as long as the fetching
  // function is using a cache.
  // Probably should move away from SWR unless we can use it in combination with
  // a TTL.

  // console.debug("useGooglePlace (skips fetch when placeId is null)", {
  //   placeId,
  //   fields,
  // });

  const { data, error } = useSWR(
    placeId ? [placeId || ""] : "",
    fetchPlaceDetails
  );
  if (error) {
    console.error(error);
  }

  return { loading, googlePlace: data, error };
}
