import { Values } from "@converge-collective/common/util";
import { Box, SxProps, TextField, Typography } from "@mui/material";
import { TZDateTimePicker } from "../TZDateTimePicker";
import Editor from "../editor/Editor";

export const FieldTypes = {
  Text: "text" as const,
  Html: "html" as const,
  Location: "location" as const,
  Category: "category" as const,
  DateTime: "datetime" as const,
} as const;

export type FieldType = Values<typeof FieldTypes>;

export type ValueTypes = string | Date;

type EditableFieldBaseProps = {
  label: string;
  fieldType: FieldType;
  value: ValueTypes;
  isEditing: boolean;
  autoFocus?: boolean;
  onChange?: (value: ValueTypes) => void;
  format?: (value: ValueTypes) => string;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  sx?: SxProps;
  displayValue?: React.ReactElement;
};

type EditableTextFieldProps = EditableFieldBaseProps & {
  fieldType: typeof FieldTypes.Text;
  value: string;
  format?: (value: string) => string;
  onChange?: (value: string) => void;
  multiline?: boolean;
  preventNewline?: boolean;
  textFieldSx?: SxProps;
  helperText?: string;
};

type EditableHtmlFieldProps = EditableFieldBaseProps & {
  fieldType: typeof FieldTypes.Html;
  value: string;
  format?: (value: string) => string;
  stickyOffset?: number;
  onChange?: (value: string) => void;
  multiline?: boolean;
  minimalControls?: boolean;
};

type EditableDateFieldProps = EditableFieldBaseProps & {
  fieldType: typeof FieldTypes.DateTime;
  value: Date;
  disablePast?: boolean;
  format?: (value: Date) => string;
  onChange?: (value: Date) => void;
};

export type EditableFieldProps =
  | EditableTextFieldProps
  | EditableHtmlFieldProps
  | EditableDateFieldProps;

/**
 * A field that can be edited by clicking on it or setting it to "editing" mode.
 * Supports:
 * - Plain text field
 * - Location autocomplete
 * - Category autocomplete
 * - Date Time picker
 */
export default function EditableField(
  props: EditableFieldProps
): React.ReactElement {
  return props.isEditing ? (
    <Box sx={props.sx}>
      {props.fieldType === FieldTypes.Text ? (
        <TextField
          fullWidth
          sx={props.textFieldSx}
          autoFocus={props.autoFocus}
          multiline={props.multiline}
          helperText={props.helperText}
          label={props.label}
          value={props.value}
          onKeyUp={props.onKeyUp}
          onKeyDown={(e) => {
            if (props.preventNewline && e.key === "Enter") {
              e.preventDefault();
              return;
            }
          }}
          onChange={(e) => {
            props.onChange?.(e.target.value);
          }}
        />
      ) : props.fieldType === FieldTypes.DateTime ? (
        <TZDateTimePicker
          label={props.label}
          minutesStep={5}
          minDateTime={new Date()}
          disablePast={props.disablePast}
          value={props.value}
          onChange={(d: Date) => props.onChange?.(d)}
        />
      ) : props.fieldType === FieldTypes.Html ? (
        <Editor
          stickyOffset={props.stickyOffset}
          initialHtml={props.value}
          placeholder={props.label}
          onChange={(newValueHtml) => props.onChange?.(newValueHtml)}
          minimalControls={props.minimalControls}
        />
      ) : (
        <>Unknown type</>
      )}
    </Box>
  ) : props.displayValue ? (
    props.displayValue
  ) : (
    <Typography variant="body1">
      {props.format ? props.format(props.value) : props.value.toString()}
    </Typography>
  );
}

// function EditableTextField({
