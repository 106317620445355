import { WithRef } from "@converge-collective/common/models/Base";
import {
  ChallengeV2Member,
  CourseChallenge,
} from "@converge-collective/common/models/ChallengeV2";
import { LiteGroup } from "@converge-collective/common/models/Group";
import {
  ChallengeV2Post,
  POST_TYPE,
} from "@converge-collective/common/models/Post";
import {
  NanoProfile,
  nanoProfile,
} from "@converge-collective/common/models/Profile";
import { AssignmentInd } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { addDoc, collection, updateDoc } from "firebase/firestore";
import { isEmpty } from "lodash";
import { useState } from "react";
import { converters } from "~/lib/converter";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { courseRoute } from "~/routes";
import { AsyncButton } from "../AsyncButton";
import AutocompleteGroup from "../AutocompleteGroup";
import UserAutocomplete from "../user/UserAutocomplete";
import {
  DocStatuses,
  DocUpdate,
} from "@converge-collective/common/models/DocMeta";

export function AssignCourseDialog({
  isOpen,
  onClose,
  course,
}: {
  isOpen: boolean;
  onClose: () => void;
  course: WithRef<CourseChallenge>;
}): React.ReactElement {
  const theme = useTheme();
  const { network, profile } = useLoggedInState();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const [assignedProfiles, setAssignedProfiles] = useState<
    WithRef<NanoProfile>[]
  >(course.assignees.map((ca) => ca.profile) || []);
  const [groups, setGroups] = useState<LiteGroup[]>(
    (course.assignedGroups || []).map((ag) => ag.group)
  );
  // validate field
  const validate = () => {
    const errs = [
      ...(isEmpty(assignedProfiles) && isEmpty(groups)
        ? ["Please select at least one group or user to assign the course to"]
        : []),
    ];

    setValidationErrors(errs);
    return errs;
  };
  const handleSubmit = async () => {
    if (validate().length > 0) {
      return false;
    }

    if (!network) {
      console.warn("network is undefined");
      return false;
    }

    if (!profile) {
      console.warn("profile is undefined");
      return false;
    }

    const now = new Date();
    const challengeMembers: ChallengeV2Member[] = assignedProfiles.map(
      (profile) => ({
        assignedAt: now,
        challengeV2Ref: course.ref,
        profile: nanoProfile(profile),
      })
    );

    // determine the new groups to assign so we can create a Post in the group
    // to announce the assignment
    const existingGroupIds = course.assignedGroupIds || [];
    const newGroups = groups.filter((g) => !existingGroupIds.includes(g.id));

    const assignedGroups = (groups || []).map((group) => ({
      assignedAt: now,
      challengeV2Ref: course.ref,
      group: group,
    }));
    const assignedGroupIds = assignedGroups.map((g) => g.group.id);

    const courseUpdates: Partial<CourseChallenge> = {
      isAssigned: !isEmpty(challengeMembers),
      assignees: challengeMembers,
      assigneeIds: assignedProfiles.map((p) => p.id),
      assignedGroups,
      assignedGroupIds,
    };

    // update the course with the assignments
    await updateDoc(
      course.ref.withConverter(converters.courseChallenge.write),
      courseUpdates
    );

    // then create a post in the groups to announce the assignment (if any)
    await Promise.all(
      newGroups.map((group) => {
        const postLog: DocUpdate = {
          date: new Date(),
          status: DocStatuses.Active,
          description: `${group.name} has been assigned the "${course.name}" course by ${profile.name}`,
          actor: nanoProfile(profile),
        };

        const challengePost: ChallengeV2Post = {
          date: now,
          eventType: POST_TYPE.ChallengeV2,
          sticky: false,
          challengeV2: { ref: course.ref },
          network,
          title: course.name,
          description: `${group.name} has been assigned the "${course.name}" course`,
          url: courseRoute(network.slug, course.slug),
          avatar: profile?.photoURL || "",
          profile,
          latestDocUpdate: postLog,
        };

        console.log(`posting to group ${group.name}`, {
          group,
          challengePost,
        });

        return addDoc(
          collection(group.ref, "posts").withConverter(
            converters.challengeV2Post.write
          ),
          challengePost
        );
      })
    );

    onClose();
    return true;
  };

  return (
    <Dialog
      fullScreen={isFullScreen}
      maxWidth="md"
      fullWidth
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        Assign "{course.name}" Course
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {network && (
            <>
              <Box>
                <UserAutocomplete
                  sx={{ width: "100%" }}
                  label="Assign to Users"
                  variant="outlined"
                  network={network}
                  multiple
                  selected={assignedProfiles}
                  onUserSelected={setAssignedProfiles}
                />
              </Box>
              <Box>
                <AutocompleteGroup
                  initSelected={groups}
                  onSetGroups={(groups) => setGroups(groups || [])}
                  multiple={true}
                  required={false}
                  label="Assign to Groups"
                />
              </Box>
            </>
          )}
        </Stack>
        {validationErrors.length > 0 && (
          <Alert severity="error" sx={{ width: "100%", mt: 2 }}>
            <AlertTitle>Couldn't assign course</AlertTitle>
            <ul>
              {validationErrors.map((v) => (
                <li key={v}>{v}</li>
              ))}
            </ul>
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <AsyncButton
          startIcon={<AssignmentInd />}
          variant="contained"
          color="primary"
          successMessage={() => {
            return `Course assigned successfully!`;
          }}
          handleSubmit={handleSubmit}
        >
          Assign Course
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
}
