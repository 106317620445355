import { ProfileWithTimestamp } from "@converge-collective/common/models/Profile";
import {
  AutoStoriesOutlined,
  Download,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Chip,
  IconButton,
  Stack,
  SxProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { isEmpty, kebabCase, sortBy, uniqBy } from "lodash";
import { ScreenSpecificTooltip } from "./ScreenSpecificTooltip";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { formatDateTime } from "@converge-collective/common/util";
import { format } from "date-fns";

type SeenReadProp = Record<string, ProfileWithTimestamp> | undefined;

export function SeenByReadBy({
  id,
  name,
  seenBy,
  readBy,
  isAdminOnly = false,
  sx,
}: {
  /** used for the generated csv export analytics */
  id: string;
  name: string;
  seenBy?: SeenReadProp;
  readBy?: SeenReadProp;
  isAdminOnly?: boolean;
  sx?: SxProps;
}) {
  const { isNetworkAdmin } = useLoggedInState();
  const exportData = () => {
    // get all profiles across both readBy and seenBy
    const allProfiles = sortBy(
      uniqBy(
        [
          ...(seenBy ? Object.values(seenBy) : []),
          ...(readBy ? Object.values(readBy) : []),
        ],
        "profile.id"
      ),
      "profile.name"
    );

    const csvData = allProfiles.map((profileWithTimestamp) => ({
      ID: profileWithTimestamp.profile.id,
      Name: profileWithTimestamp.profile.name,
      "Seen At":
        (seenBy &&
          seenBy[profileWithTimestamp.profile.id] &&
          formatDateTime(seenBy[profileWithTimestamp.profile.id].timestamp)) ||
        "",
      "Read At":
        (readBy &&
          readBy[profileWithTimestamp.profile.id] &&
          formatDateTime(readBy[profileWithTimestamp.profile.id].timestamp)) ||
        "",
    }));
    console.log(csvData, { csvData });
    const timestamp = format(new Date(), "yyyy-MM-dd-HH-mm-ss");
    const csvConfig = mkConfig({
      filename: `${kebabCase(name)}-${id}-${timestamp}`,
      useKeysAsHeaders: true,
    });
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig)(csv);
  };

  // network admins can export the stats

  if (!seenBy && !readBy) return null;
  return (
    <Chip
      sx={sx}
      variant="outlined"
      size="small"
      color={isAdminOnly ? "error" : "primary"}
      label={
        <Stack direction="row" alignItems="center" spacing={1}>
          {isAdminOnly && (
            <ScreenSpecificTooltip title="Only Network Admins can see this">
              Admin
            </ScreenSpecificTooltip>
          )}
          {seenBy && (
            <SeenReadTag
              data={seenBy}
              label="Seen"
              icon={<VisibilityOutlined sx={{ fontSize: 20 }} />}
            />
          )}
          {readBy && (
            <SeenReadTag
              data={readBy}
              label="Read"
              icon={<AutoStoriesOutlined sx={{ fontSize: 20 }} />}
            />
          )}

          {isNetworkAdmin && (
            <Tooltip title="Export Analytics (Admin Only)" arrow>
              <IconButton
                onClick={exportData}
                sx={{
                  fontSize: 10,
                  borderRadius: 0,
                  padding: 0,
                }}
                color="error"
              >
                <Download sx={{ fontSize: 20 }} />
                Export
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      }
    />
  );
}

type TagProps = {
  data: Record<string, ProfileWithTimestamp>;
  label: string;
  icon: React.ReactElement;
};

const maxLabels = 12;

export const SeenReadTag = ({ data, label, icon }: TagProps) => {
  if (isEmpty(data) || !data) return null;

  const names = Object.values(data)
    .filter(({ profile }) => profile)
    .map(({ profile }) => profile.name);

  const namesLabel =
    names.length <= maxLabels
      ? names.join(", ")
      : `${names.slice(0, maxLabels).join(", ")} + ${names.length - maxLabels}`;

  return (
    <Stack
      sx={{ cursor: "pointer" }}
      direction="row"
      alignItems="center"
      gap={0.5}
    >
      <ScreenSpecificTooltip title={`${label} by ${namesLabel}`}>
        {icon}
      </ScreenSpecificTooltip>
      <Typography variant="caption" lineHeight={2} color="inherit">
        {Object.values(data).length}
      </Typography>
    </Stack>
  );
};
