import { Box, SxProps } from "@mui/material";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";

export function VisibleForTime({
  children,
  durationVisible,
  onVisibleForTime,
  active = true,
  threshold = 0.75,
  sx,
}: {
  children: React.ReactElement;
  durationVisible: number;
  onVisibleForTime: () => void;
  active?: boolean;
  threshold?: number;
  sx?: SxProps;
}) {
  const { inView, ref } = useInView({
    threshold,
    triggerOnce: false,
    trackVisibility: false, // Disabled for performance
    skip: !active,
  });

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hasTriggered = useRef(false);

  const isDebugEnabled = false;

  useEffect(() => {
    if (inView && !hasTriggered.current) {
      // Start the timer when visible
      isDebugEnabled &&
        console.log("visible for time is in view and not yet triggered", {
          inView,
          hasTriggered,
          durationVisible,
        });
      timeoutRef.current = setTimeout(() => {
        isDebugEnabled &&
          console.log("triggering visible for time", {
            inView,
            hasTriggered,
            durationVisible,
          });
        onVisibleForTime();
        // Ensure it only triggers once per visibility session
        hasTriggered.current = true;
      }, durationVisible);
    } else if (!inView) {
      // Clear timeout and allow re-triggering only if the component is out of
      // view for some time
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      // Reset `hasTriggered` only if it was previously set
      if (hasTriggered.current) {
        hasTriggered.current = false;
      }
    }

    return () => {
      // Cleanup on unmount
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [inView, durationVisible, onVisibleForTime]);

  const addStyle = isDebugEnabled
    ? hasTriggered.current
      ? { border: "2px solid green" }
      : { border: "2px solid red" }
    : {};

  const className = `visible-for-time visible-for-time-${inView}`;

  return (
    <Box className={className} sx={{ ...sx, ...addStyle }} ref={ref}>
      {children}
    </Box>
  );
}
