import {
  AccessLevels,
  AuthzGrants,
} from "@converge-collective/common/models/Authz";
import { WithRef } from "@converge-collective/common/models/Base";
import { Doc } from "@converge-collective/common/models/Doc";
import { DriveFileMove } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { updateDoc } from "firebase/firestore";
import { useState } from "react";
import { FolderNodeInfo, subfolderOrRoot } from "~/lib/folder";
import { createAlert } from "~/lib/globalAlerts";
import { AutocompleteFolder } from "./AutocompleteFolder";
import {
  AuthorizedActionTypes,
  AuthorizedActions,
  canPerformAction,
} from "~/lib/authz";
import { userAccessLevel } from "~/hooks/useAuthz";
import { useLoggedInState } from "~/lib/useLoggedInState";

export function MoveDocDialog({
  isOpen,
  onClose,
  doc,
}: {
  isOpen: boolean;
  onClose: () => void;
  doc: WithRef<Doc>;
}): React.ReactElement {
  const { isNetworkAdmin, profile } = useLoggedInState();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactElement>();
  const [folderInfo, setFolderInfo] = useState<FolderNodeInfo | null>(null);

  const isDocEditable = (authzGrants: AuthzGrants) => {
    const accessLevel = userAccessLevel(
      authzGrants,
      isNetworkAdmin === true,
      profile
    );
    return canPerformAction(AuthorizedActionTypes.DocsEdit, accessLevel);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (!folderInfo) {
        setErrorMessage(<>Please select a folder</>);
        setIsLoading(false); // Ensure loading state is reset if there's an error
        return;
      }

      if (!isDocEditable(doc.authzGrants)) {
        createAlert({
          message: "Not authorized to move docs from this folder",
          severity: "error",
        });
        return;
      }

      // NOTE: we could put it in multiple folders by using arrayUnion if we
      // want

      await updateDoc(doc.ref, {
        folderPaths: [folderInfo.slug],
        authzGrants: folderInfo.root.authzGrants,
      });
      createAlert({
        message: `Doc moved to ${subfolderOrRoot(folderInfo).name}`,
        severity: "success",
      });
      reset();
      onClose();
    } catch (e) {
      console.error(e);
      setErrorMessage(<>Error moving doc: {e.message}</>);
    } finally {
      setIsLoading(false);
    }
  };

  const reset = () => {
    setIsLoading(false);
    setErrorMessage(undefined);
    setFolderInfo(null);
  };

  const _onClose = () => {
    reset();
    onClose();
  };

  const isPublicFolder =
    doc.authzGrants.access.networkWide.accessLevel === AccessLevels.None;

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={_onClose}>
      <DialogTitle>Move Doc to Folder</DialogTitle>

      {!!isPublicFolder && (
        <Alert severity="error">This is Private folder</Alert>
      )}
      <DialogContent>
        <div style={{ marginTop: 6 }} />
        <AutocompleteFolder
          onFolderSelected={(fi) => {
            setFolderInfo(fi);
          }}
          removeIds={doc.folderPaths}
          authzFilter={{
            hasMinAccessLevel:
              AuthorizedActions[AuthorizedActionTypes.FoldersEdit],
          }}
        />
        {errorMessage && !folderInfo && (
          <Alert sx={{ mt: 2 }} severity="error">
            {errorMessage}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={_onClose}>Cancel</Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={isLoading}
          variant="contained"
          color="primary"
          startIcon={<DriveFileMove />}
        >
          Move Doc
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
