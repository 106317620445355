import { Box, ClickAwayListener, useMediaQuery, useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";

export function ScreenSpecificTooltip({
  children,
  title,
}: React.PropsWithChildren<{ title: string }>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openTooltip, setOpenTooltip] = useState(false);

  if (isMobile) {
    return (
      <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
        <Tooltip open={openTooltip} arrow title={title}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={0.3}
            onClick={() => setOpenTooltip((p) => !p)}
          >
            {children}
          </Box>
        </Tooltip>
      </ClickAwayListener>
    );
  } else {
    return (
      <Tooltip arrow title={title}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={0.3}
        >
          {children}
        </Box>
      </Tooltip>
    );
  }
}
