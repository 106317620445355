import React, { useCallback } from "react";
import { WithRef } from "@converge-collective/common/models/Base";
import { POST_TYPE, Post } from "@converge-collective/common/models/Post";
import { Profile } from "@converge-collective/common/models/Profile";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { FileError, useDropzone } from "react-dropzone";
import { useFirestore, useStorage } from "reactfire";
import { deferred, writeConverter } from "@converge-collective/common/util";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  UploadTaskSnapshot,
} from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { ActivityLog } from "@converge-collective/common/models/ActivityLog";

export default function AddPostPhotos({
  post,
  profile,
  setCountLoading,
  onFileUploadError,
}: {
  post: WithRef<Post>;
  profile: Profile;
  setCountLoading: (count: number) => void;
  onFileUploadError: (error: string | undefined) => void;
}): React.ReactElement {
  const firestore = useFirestore();
  const storage = useStorage();
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setCountLoading(acceptedFiles.length);
      const uploadPromises = acceptedFiles.map((file: File) => {
        const { promise, resolve, reject } = deferred<UploadTaskSnapshot>();

        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          // const binaryStr = reader.result;

          const time = new Date().getTime();
          const ext = file.name
            .substring(file.name.lastIndexOf(".") + 1)
            .toLowerCase();
          const path = `images/posts/${post.id}/${profile.id}/${time}.${ext}`;
          const imageDir = ref(storage, path);

          const task = uploadBytesResumable(imageDir, file);
          task.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.ceil(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              console.log(progress);
            },
            (error) => reject(`error uploading file ${error}`),
            () => resolve(task.snapshot)
          );
        };
        reader.readAsArrayBuffer(file);
        return promise;
      });
      // wait for all uploads to complete
      const uploads = await Promise.all(uploadPromises);
      // then get the upload urls and update the post
      const postPhotoUrls = await Promise.all(
        uploads.map((u) => getDownloadURL(u.ref))
      );
      console.log("updating post with photo urls", {
        post,
        postPhotoUrls,
        profile,
      });
      await updateDoc(post.ref, {
        photoUrls: postPhotoUrls,
      });

      // if the post has an activity, post the photos directly on the activity
      // as well, which boosts the activity's points
      if (post.eventType === POST_TYPE.PointsEarned) {
        const activity = post.activityLog;
        const activityId = activity.id;
        const activityRef = doc(
          firestore,
          "profiles",
          profile.id,
          "activityLogs",
          activityId
        ).withConverter(writeConverter<ActivityLog>());
        updateDoc(activityRef, {
          photoUrls: postPhotoUrls,
        });
      }

      setCountLoading(0);
    },
    [setCountLoading, post, profile, storage, firestore]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
      "video/*": [],
    },
    onDropRejected: () => {
      onFileUploadError("Invalid File type selected");
    },
    onDropAccepted: () => {
      onFileUploadError(undefined);
    },
  });

  const color = isDragActive ? "secondary" : "primary";

  return (
    <div {...getRootProps()}>
      <input id={`pick-file-${post.id}`} {...getInputProps()} />
      <label htmlFor={`pick-file-${post.id}`}>
        <IconButton size="small" aria-label="add photos">
          <Tooltip title="Add Photos or Videos" arrow>
            <AddAPhotoOutlinedIcon color={color} />
          </Tooltip>
        </IconButton>
      </label>
    </div>
  );
}
