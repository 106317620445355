import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  "@keyframes titleAnimation": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  root: {
    marginTop: 30,
    marginBottom: 30,
  },
  subtitle: {
    lineHeight: "1.2em",
    fontSize: "1.4em",
    fontWeight: "300",
    color: "#333",
  },
}));

export default function CenterHeadline({
  title,
  children,
}: {
  title: React.ReactNode;
  children?: React.ReactNode;
}): React.ReactElement {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      <Typography
        sx={{
          fontWeight: 400,
          mb: 1,
          animation: "1s ease-in-out 0s 1 $titleAnimation",
          fontSize: "2.7em",
          textAlign: "center",
        }}
        variant="h2"
      >
        {title}
      </Typography>

      <Box display="flex" justifyContent="center">
        {typeof children === "string" ? (
          <Typography
            sx={{
              maxWidth: "md",
              px: 2,
              orphans: 3,
              widows: 3,
            }}
            className={classes.subtitle}
            align="center"
            variant="subtitle1"
            gutterBottom
          >
            {children}
          </Typography>
        ) : (
          children
        )}
      </Box>
    </div>
  );
}
