import { cappedPointDesc } from "@converge-collective/common/models/ActivityLog";
import { DailyPoints } from "@converge-collective/common/models/Leaderboard";
import { addDays, endOfWeek, format, getDay, startOfWeek } from "date-fns";
import { groupBy, isEmpty, range } from "lodash";
import React from "react";
import CircularProgressWithLabel from "../CircularWithLabel";

export default function DailyPointsCircles({
  dailyPoints,
}: {
  dailyPoints: DailyPoints[];
}): React.ReactElement {
  if (isEmpty(dailyPoints)) {
    return <div />;
  }

  const pointsByDay = groupBy(dailyPoints, (p) => getDay(p.date));

  const pointsForDay = (day: number): DailyPoints | undefined =>
    pointsByDay[day] && pointsByDay[day][0];

  // get the first day of the week that this activity was posted
  const [firstDayPoints] = dailyPoints;
  const firstDate = new Date(firstDayPoints.date);
  const sunday = startOfWeek(firstDate);
  // compute the saturday for this week (could be far in the past)
  const saturday = endOfWeek(sunday);
  const now = new Date();
  const maxDate = now > saturday ? saturday : now;

  // console.log("DailyPointsCircles", {
  //   firstDayPoints,
  //   firstDate,
  //   sunday,
  //   saturday,
  // });

  // loop from sunday to today or to the last day of the week that this activity
  // was posted
  return (
    <>
      {range(0, getDay(maxDate) + 1)
        .map(pointsForDay)
        .map((points, idx) => (
          <div style={{ marginRight: "5px" }} key={idx}>
            {points && points.cap ? (
              <CircularProgressWithLabel
                size={40}
                thickness={4}
                color={
                  points.categoryPoints / points.cap < 0.1
                    ? "error"
                    : points.categoryPoints === points.cap
                      ? "primary"
                      : points.categoryPoints / points.cap > 0.5
                        ? "success"
                        : "secondary"
                }
                tooltip={cappedPointDesc({
                  cap: points.cap,
                  categoryPoints: points.categoryPoints,
                  goalPoints: points.goalPoints,
                  peerRecognitionPoints: points.peerRecognitionPoints,
                })}
                label={format(points.date, "EEE")}
                value={Math.max(1, (points.categoryPoints / points.cap) * 100)}
              />
            ) : (
              <CircularProgressWithLabel
                tooltip={
                  points?.categoryPoints
                    ? `${points.categoryPoints} points`
                    : `No points for this day.`
                }
                size={40}
                thickness={4}
                color="error"
                label={format(addDays(sunday, idx), "EEE")}
                value={points?.categoryPoints ? 100 : 0}
              />
            )}
          </div>
        ))}
    </>
  );
}
