import { WithID, WithRef } from "@converge-collective/common/models/Base";
import {
  DocStatus,
  DocStatuses,
} from "@converge-collective/common/models/DocMeta";
import {
  Group,
  LiteGroupMember,
} from "@converge-collective/common/models/Group";
import { Network } from "@converge-collective/common/models/Network";
import { converter } from "@converge-collective/common/util";
import { Archive, Lock } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  AvatarGroup,
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Stack,
  SxProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { DocumentReference, doc } from "firebase/firestore";
import { isEmpty, take } from "lodash";
import Link from "next/link";
import { useFirestoreDoc } from "reactfire";
import JoinLeaveGroupButton from "~/components/groups/JoinLeaveGroupButton";
import { useNetworkMembership } from "~/hooks/useNetworkMembership";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { groupDetailRoute } from "~/routes";
import { AdminChip } from "../AdminChip";
import Wrap from "../Wrap";
import UserAvatar from "../user/UserAvatar";
import { AchiveGroupAction } from "./ArchiveGroupAction";
import { ArchivedGroup } from "./ArchivedGroup";

const GroupMember = ({ groupMember }: { groupMember: LiteGroupMember }) => {
  return (
    <Tooltip
      key={groupMember.id}
      title={<span style={{ marginLeft: 2 }}>{groupMember.profile.name}</span>}
    >
      <UserAvatar profile={groupMember.profile} />
    </Tooltip>
  );
};

const GroupCard = ({
  network,
  group,
  groupRef,
  sx,
  dense = false,
}: {
  network: WithRef<Network>;
  group: WithID<Group>;
  groupRef?: DocumentReference;
  sx?: SxProps;
  dense?: boolean;
}) => {
  const { slug: networkSlug } = network;
  const { photoUrl, name, leaders, sponsors, members, memberIds, color } =
    group;
  const { profile, isNetworkAdmin } = useLoggedInState();

  const { networkMembership } = useNetworkMembership({
    network,
    profile,
  });

  const isMember = Object.keys(
    networkMembership?.groupMemberships || {}
  ).includes(group.id);
  const isActivated = !isEmpty(leaders);
  const latestGroupDocSnap = doc(network.ref, "groups", group.id).withConverter(
    converter<Group>()
  );
  const latestGroupDoc = useFirestoreDoc(latestGroupDocSnap);
  const groupStatus =
    latestGroupDoc.status === "success"
      ? latestGroupDoc.data.data()?.latestDocUpdate.status
      : undefined;
  const validStatuses: DocStatus[] = [
    DocStatuses.Published,
    DocStatuses.Active,
  ];
  const isArchived = groupStatus ? !validStatuses.includes(groupStatus) : false;

  // only network admins and members can see the current members
  const sortedMembers =
    !group.isPrivate || isNetworkAdmin || isMember
      ? (members || []).sort((gm) => (!isEmpty(gm.profile.photoURL) ? -1 : 1))
      : [];

  if (latestGroupDoc.status === "loading") {
    return (
      <>
        <Card
          component={Stack}
          direction={"row"}
          columnGap={2}
          variant="outlined"
          p={2}
        >
          <CircularProgress size={16} />
          <Typography variant="body1">Loading</Typography>
        </Card>
      </>
    );
  }

  //  if (isArchived) {
  //    return (
  //      <>
  //        <Card
  //          component={Stack}
  //          direction={"row"}
  //          columnGap={2}
  //          variant="outlined"
  //          p={2}
  //        >
  //          <Typography variant="body1" fontWeight={"bold"}>
  //            The {group.name} group has been archived.
  //          </Typography>
  //        </Card>
  //      </>
  //    );
  //  }

  const coverHeight = dense ? 100 : 140;

  return (
    <Card
      sx={{
        ...sx,
        "&:hover": {
          boxShadow: `0 4px 20px 0 rgba(0, 0, 0, 0.4)}`,
        },
        // capture the Backdrop below
        "& .MuiCard-root": {
          position: "relative",
        },
      }}
      variant="outlined"
    >
      {isArchived && <ArchivedGroup group={group} />}
      <Wrap
        condition={isMember || isEmpty(group.joinCode)}
        wrapper={(children) => (
          <Link
            href={groupDetailRoute(networkSlug, group.slug)}
            style={{ textDecoration: "none" }}
          >
            {children}
          </Link>
        )}
      >
        {!isEmpty(photoUrl) ? (
          <Box
            sx={{
              height: coverHeight,
              backgroundImage: `
                  linear-gradient(to bottom, rgba(0, 0, 0, 0.5),
                  rgba(0, 0, 0, ${isEmpty(sponsors) ? 0 : 0.6})),
                  url("${photoUrl}")
                `,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <Box
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {!isEmpty(sponsors)
                ? sponsors?.map((sponsor) => (
                    <img
                      key={sponsor.name}
                      src={sponsor.logoUrl}
                      alt={sponsor.name}
                      style={{ maxHeight: 90 }}
                    />
                  ))
                : null}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              height: coverHeight,
              backgroundColor: color,
            }}
          />
        )}
      </Wrap>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Wrap
            condition={isMember || isEmpty(group.joinCode)}
            wrapper={(children) => (
              <Link
                href={groupDetailRoute(networkSlug, group.slug)}
                style={{ textDecoration: "none" }}
              >
                {children}
              </Link>
            )}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              {group.isPrivate ? (
                <Tooltip title="Group is private">
                  <Lock sx={{ color: "info.main", mr: 1 }} />
                </Tooltip>
              ) : isActivated ? (
                <Tooltip title="Group is activated">
                  <CheckCircleIcon sx={{ color: "info.main", mr: 1 }} />
                </Tooltip>
              ) : null}
              <Typography variant="h5" title={group.id}>
                {name}
              </Typography>
            </Stack>
          </Wrap>
        </Stack>

        {!dense && sortedMembers.length > 0 && (
          <AvatarGroup
            total={memberIds?.length || 0}
            spacing="small"
            sx={{
              justifyContent: "flex-end",
              minHeight: 44,
              mt: 1,
              ml: 0,
            }}
          >
            {take(sortedMembers, 6).map((groupMember) => (
              <GroupMember key={groupMember.id} groupMember={groupMember} />
            ))}
          </AvatarGroup>
        )}

        {!dense && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 2 }}
          >
            {profile && groupRef ? (
              <JoinLeaveGroupButton
                network={network}
                profile={profile}
                group={{ ...group, ref: groupRef } as WithRef<Group>}
              />
            ) : null}

            {isNetworkAdmin && !isArchived && (
              <AchiveGroupAction
                group={{ ...group, ref: groupRef! }}
                profile={profile!}
              >
                <AdminChip>
                  <Tooltip title="Archive Group">
                    <IconButton size="small" color="inherit">
                      <Archive fontSize="inherit" color="inherit" />
                    </IconButton>
                  </Tooltip>
                </AdminChip>
              </AchiveGroupAction>
            )}
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default GroupCard;
