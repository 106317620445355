import { WithRef } from "@converge-collective/common/models/Base";
import { Doc } from "@converge-collective/common/models/Doc";
import { Network } from "@converge-collective/common/models/Network";
import { VisibleForTime } from "../VisibleForTime";
import { getDoc } from "firebase/firestore";
import { DocCard } from "./DocCard";
import { Box, Skeleton } from "@mui/material";
import { converters } from "~/lib/converter";
import { useState } from "react";

export function LazyDocCard({
  doc: partialDoc,
  network,
}: {
  doc: Pick<WithRef<Doc>, "ref">;
  network: WithRef<Network>;
}): React.ReactElement {
  const [doc, setDoc] = useState<WithRef<Doc>>();

  const loadDoc = async () => {
    if (!doc) {
      const fullDoc = await getDoc(
        partialDoc.ref.withConverter(converters.doc.read)
      );
      setDoc(fullDoc.data());
    }
  };

  return (
    <VisibleForTime
      durationVisible={100}
      onVisibleForTime={() => {
        loadDoc();
      }}
      sx={{ width: "100%" }}
    >
      {doc ? (
        <DocCard doc={doc} network={network} />
      ) : (
        <Box>
          <Skeleton width="100%" variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton width="100%" variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton width="100%" variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton
            sx={{ mt: 1 }}
            width="100%"
            variant="rounded"
            height={160}
          />
        </Box>
      )}
    </VisibleForTime>
  );
}
