import { SupportedImageSize } from "@converge-collective/common/util";
import { Dialog } from "@mui/material";
import React from "react";

export default function Media({ src }: { src: string }): React.ReactElement {
  const types = new Map([
    ["jpg", "img"],
    ["gif", "img"],
    ["mp4", "video"],
    ["3gp", "video"],
    ["mov", "video"],
    ["webm", "video"],
  ]);

  const url = new URL(src);
  const extension = url.pathname.split(".").pop();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const type = types.get(extension || "");

  const mediaElement = (size: SupportedImageSize, isFullscreen = false) =>
    type === "video" ? (
      <video
        controls
        onClick={handleClose}
        src={src}
        className="MuiImageListItem-img"
        playsInline
        autoPlay={false}
        muted
        loop
        style={{
          objectFit: isFullscreen ? "contain" : "cover",
          objectPosition: "50% 50%",
          height: "100%",
          width: "100%",
          maxHeight: isFullscreen ? "auto" : 500,
        }}
      />
    ) : (
      <>
        {/*
          NOTE: the sized image won't be ready right away, so use the full size
          as a backup.

          NOTE: we used to use the `Img` component but it unexpectedly causes
          the page to freeze and become unresponsive.
          */}
        <img
          onClick={() => setOpen(false)}
          alt="media"
          className="MuiImageListItem-img"
          style={{
            objectFit: isFullscreen ? "contain" : "cover",
            objectPosition: "50% 50%",
            height: "100%",
            width: "100%",
          }}
          src={src}
          loading="lazy"
        />
      </>
    );

  return (
    <>
      <div
        style={{ height: "100%" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {mediaElement(600, false)}
      </div>
      <Dialog fullScreen onClose={handleClose} open={open}>
        <div
          style={{ width: "100%", height: "100%" }}
          onClick={() => {
            setOpen(false);
          }}
        >
          {mediaElement(1500, true)}
        </div>
      </Dialog>
    </>
  );
}
