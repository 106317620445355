import Info from "@mui/icons-material/Info";
import { Backdrop, Stack, SxProps, Typography } from "@mui/material";
import { ComponentProps } from "react";
import { Event } from "@converge-collective/common/models/Event";

// get the type of the Mui Typography variant prop
type TypographyVariant = ComponentProps<typeof Typography>["variant"];

/**
 * NOTE: users of this component should place it in a container with `position:
 * relative`
 */
export function ArchivedEvent({
  event,
  typographyVariant = "h5",
  sx,
}: {
  event: Event;
  typographyVariant?: TypographyVariant;
  sx?: SxProps;
}) {
  return (
    <Backdrop
      sx={{
        ...sx,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        position: "absolute",
        zIndex: "9",
        cursor: "pointer",
        px: 1,
      }}
      open
    >
      <Stack direction="row" alignItems="start" gap={1}>
        <Info color="light" fontSize="medium" />
        <Typography variant={typographyVariant} color="white">
          The <b>{event.title}</b> event has been archived.
        </Typography>
      </Stack>
    </Backdrop>
  );
}
