import { WithRef } from "@converge-collective/common/models/Base";
import { ChallengeKinds } from "@converge-collective/common/models/Challenge";
import {
  ChallengeV2Member,
  CourseChallenge,
} from "@converge-collective/common/models/ChallengeV2";
import {
  DocStatuses,
  DocUpdate,
  appendDocUpdate,
} from "@converge-collective/common/models/DocMeta";
import { liteProfile } from "@converge-collective/common/models/Profile";
import { permissions } from "@converge-collective/common/models/rbac/NetworkPermission";
import { AssignmentInd, ContentCopy } from "@mui/icons-material";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import {
  AvatarGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
  darken,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  collection,
  doc,
  getDocs,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import Link from "next/link";
import { useState } from "react";
import { useFirestore } from "reactfire";
import { useChallengeCompletion } from "~/hooks/useChallenges";
import { usePermission } from "~/hooks/usePermission";
import { backgroundColors } from "~/lib/color";
import { createAlert } from "~/lib/globalAlerts";
import { slugify } from "~/lib/slug";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { courseRoute, coursesRoute } from "~/routes";
import ConfirmDialog from "../ConfirmDialog";
import { ExpandPanel } from "../ExpandPanel";
import { RenderHTML } from "../RenderHTML";
import { TagChip } from "../TagChip";
import UserAvatar from "../user/UserAvatar";
import { AssignCourseDialog } from "./AssignCourseDialog";
import { useRouter } from "next/router";
import { ViewStatsCapture } from "../ViewStatsCapture";

const Assignee = ({ assignee }: { assignee: ChallengeV2Member }) => {
  return (
    <Tooltip title={assignee.profile.name}>
      <UserAvatar profile={assignee.profile} />
    </Tooltip>
  );
};

export function CourseCard({
  course,
}: {
  course: WithRef<CourseChallenge>;
}): React.ReactElement {
  const { network, profile } = useLoggedInState();
  const router = useRouter();
  const firestore = useFirestore();
  const bgImage = course.photoUrls[0];
  const status = course.latestDocUpdate.status;

  const [courseToAssign, setCourseToAssign] =
    useState<WithRef<CourseChallenge>>();
  const [isDeleteClicked, setIsDeleteClicked] = useState<boolean>(false);
  const [isDuplicating, setIsDuplicating] = useState<boolean>(false);
  const bgColor = course.backgroundColor || backgroundColors[0];

  const { courseProgress, hasSections, completedSections, sectionCount } =
    useChallengeCompletion(course, profile);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // console.log(`CourseChallenge ${course.name}`, {
  //   course,
  //   courseProgress,
  //   hasSections,
  //   sectionCount,
  //   completedSections,
  // });
  //

  const { canPerform: canCreate } = usePermission(
    permissions.coursesCreateEdit
  );
  const { canPerform: canAssign } = usePermission(permissions.coursesAssign);
  const { canPerform: canArchive } = usePermission(permissions.coursesArchive);

  const cardStyles = bgImage
    ? {
        backgroundImage: `
          linear-gradient(to bottom, rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.6)),
          url("${bgImage}")
        `,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }
    : {
        background: `linear-gradient(to right bottom, ${darken(bgColor, 0.2)}, ${darken(bgColor, 0.9)})`,
      };

  const deleteCourse = async (c: WithRef<CourseChallenge>): Promise<void> => {
    if (isDeleteClicked && profile) {
      const newUpdateLog: DocUpdate = {
        date: new Date(),
        status: DocStatuses.Deleted,
        description: `${profile.name} removed course: ${course.name}.`,
        actor: liteProfile(profile),
      };

      await updateDoc(c.ref, appendDocUpdate(newUpdateLog, c));
      setIsDeleteClicked(false);
    }
  };

  const handleDuplicateCourse = async () => {
    if (!network || !course || !profile) return;
    try {
      const date = new Date();
      const name = course.name + " copy";
      const slug = slugify(name, date);
      const courseRef = doc(network.ref, "challengesV2", slug);

      const docUpdate: DocUpdate = {
        date,
        description: `${name} course duplicated in Draft mode by ${profile.name}`,
        status: DocStatuses.Draft,
        actor: liteProfile(profile),
      };

      const newCourse: CourseChallenge = {
        ...course,
        createdAt: date,
        kind: ChallengeKinds.CourseChallenge,
        slug,
        name: name,
        latestDocUpdate: docUpdate,
        assigneeIds: [],
        assignees: [],
        challengeMemberUids: [],
        assignedGroupIds: [],
        assignedGroups: [],
      };

      const batch = writeBatch(firestore);
      batch.set(courseRef, newCourse);

      // Course Blocks
      const blocksCol = collection(course.ref, "challengeBlocks");
      const blocks = await getDocs(blocksCol);

      await Promise.all(
        blocks.docs.map(async (block) => {
          const newCourseBlocksCol = collection(courseRef, "challengeBlocks");
          const blockDoc = doc(newCourseBlocksCol, block.id);
          batch.set(blockDoc, block.data());

          const blockSectionsCol = collection(block.ref, "challengeSections");
          const sections = await getDocs(blockSectionsCol);

          sections.docs.map((section) => {
            const newBlockSectionsCol = collection(
              blockDoc,
              "challengeSections"
            );
            const sectionDoc = doc(newBlockSectionsCol, section.id);
            batch.set(sectionDoc, {
              ...section.data(),
              challengeV2Id: slug,
            });
          });
        })
      );
      await batch.commit();
    } catch (e) {
      console.log("Course duplicating error: ", e);
    } finally {
      setIsDuplicating(false);
    }
  };
  const PublishStatus =
    status === DocStatuses.Published
      ? "Assign course to users and groups"
      : "The course must be published before it can be assigned to users and groups";
  const courseUrl = courseRoute(network?.slug || "", course.slug);
  return (
    <Card
      sx={{
        display: "flex",
        maxWidth: isMobile ? "382px" : "100%",
        flexDirection: "column",
      }}
    >
      <CardMedia
        component={Link}
        href={courseUrl}
        sx={{
          height: 140,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          textDecoration: "none",
          ...cardStyles,
        }}
      >
        <Typography
          sx={{ textAlign: "center", px: 1 }}
          variant="h5"
          color={"primary.contrastText"}
        >
          {course.name}
        </Typography>
      </CardMedia>
      {hasSections && (
        <Tooltip
          title={`Completed ${completedSections.length} / ${sectionCount} sections`}
          arrow
        >
          <LinearProgress
            sx={{ height: 8 }}
            variant="determinate"
            value={courseProgress}
          />
        </Tooltip>
      )}
      <CardContent sx={{ mb: 0 }}>
        <ViewStatsCapture entity={course.ref}>
          {network && course?.tags && (
            <Stack
              gap={1}
              sx={{ mb: 1, flexWrap: "wrap" }}
              direction="row"
              alignItems="center"
            >
              {course.tags.map((tag) => (
                <TagChip
                  tag={tag}
                  key={tag.id}
                  onClick={() => {
                    if (!network) return;

                    const activePathname = coursesRoute(network.slug);
                    const existingTags =
                      typeof router.query.tags === "string"
                        ? [router.query.tags]
                        : router.query.tags;

                    if (existingTags && existingTags.includes(tag.slug)) {
                      router.push(
                        {
                          pathname: activePathname,
                          query: {
                            tags: existingTags.filter((t) => t !== tag.slug),
                          },
                        },
                        undefined,
                        { shallow: true }
                      );
                    } else {
                      router.push(
                        {
                          pathname: activePathname,
                          query: {
                            tags: existingTags
                              ? [...existingTags, tag.slug]
                              : [tag.slug],
                          },
                        },
                        undefined,
                        { shallow: true }
                      );
                    }
                  }}
                />
              ))}
            </Stack>
          )}
          <ExpandPanel maxCollapsedSize={120}>
            <RenderHTML html={course.description} />
          </ExpandPanel>
        </ViewStatsCapture>
      </CardContent>
      <CardActions sx={{ pt: 0, mt: "auto" }}>
        <Stack
          direction="row"
          sx={{ width: "100%", justifyContent: "space-between", mt: 1 }}
        >
          <AvatarGroup
            max={5}
            spacing="small"
            sx={{
              justifyContent: "flex-end",
              minHeight: 44,
            }}
          >
            {course.assignees?.map((assignee) => (
              <Assignee key={assignee.profile.id} assignee={assignee} />
            ))}
          </AvatarGroup>

          <Stack direction="row" alignItems="center">
            {canAssign && (
              <Tooltip title={PublishStatus}>
                <div>
                  <IconButton
                    disabled={status === DocStatuses.Draft}
                    onClick={() => setCourseToAssign(course)}
                    color="primary"
                  >
                    <AssignmentInd color="inherit" />
                  </IconButton>
                </div>
              </Tooltip>
            )}
            {canCreate && (
              <Tooltip title="Duplicate Course">
                <IconButton
                  sx={{ mt: -0.2 }}
                  size="medium"
                  disabled={isDuplicating}
                  onClick={() => setIsDuplicating(true)}
                >
                  <ContentCopy fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
            {canArchive && (
              <Tooltip title="Archive Course">
                <IconButton
                  color="warning"
                  sx={{ mt: -0.2 }}
                  size="medium"
                  onClick={() => {
                    setIsDeleteClicked(true); // Set the flag indicating the delete button is clicked
                  }}
                >
                  <DeleteOutlined color="inherit" fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </CardActions>
      {courseToAssign && (
        <AssignCourseDialog
          isOpen={Boolean(courseToAssign)}
          onClose={() => setCourseToAssign(undefined)}
          course={courseToAssign}
        />
      )}
      {isDeleteClicked && (
        <ConfirmDialog
          isOpen={isDeleteClicked}
          close={() => {
            setIsDeleteClicked(false); // Reset the flag when the dialog is closed
          }}
          confirmTitle={`Are you sure you want to archive the "${course?.name}" course and all of its content?`}
          confirmButtonTitle={`Archive "${course?.name}"`}
          proceedWithAction={() => deleteCourse(course)}
          onSuccess={() => {
            createAlert({ message: `"${course?.name}" Course archived` });
          }}
        />
      )}
      {isDuplicating && (
        <ConfirmDialog
          severity="info"
          isOpen={isDuplicating}
          close={() => setIsDuplicating(false)}
          confirmTitle={`Are you sure you want to duplicate the "${course?.name}" course?`}
          confirmButtonTitle={`Duplicate "${course?.name}"`}
          proceedWithAction={handleDuplicateCourse}
          onSuccess={() =>
            createAlert({ message: `"${course?.name}" Course duplicated` })
          }
        />
      )}
    </Card>
  );
}
