import { Notification } from "@converge-collective/common/models/Notification";
import { modelFromSnap } from "@converge-collective/common/util";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { sanitizeAllHtml } from "~/lib/browser-util";
import { useLoggedInState } from "~/lib/useLoggedInState";

declare const window: any;

export function ElectronBridge() {
  const { profile } = useLoggedInState();
  const [now] = useState(new Date());
  useEffect(() => {
    if (profile) {
      // subscribe to the notifications firestore collection
      const unsub = onSnapshot(
        query(
          collection(profile.ref, "notifications"),
          where("date", ">", now)
        ),
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              const notification = modelFromSnap<Notification>(change.doc);
              const simplifiedNotification = {
                bodyHtml: sanitizeAllHtml(notification.bodyHtml),
                date: notification.date.toString(), // Convert to string
                id: notification.id,
                isRead: notification.isRead,
                kind: notification.kind,
                linkText: notification.linkText,
                linkUrl: notification.linkUrl,
                title: notification.title,
                subtitle: notification.subtitle,
                profileName: notification.profile?.name, // Extract only the name
              };
              console.log("ElectronBridge notification", {
                simplifiedNotification,
              });
              window.electronAPI?.sendObject(simplifiedNotification);
            }
          });
        }
      );
      return () => unsub();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.id]);

  return <></>;
}
