import { WithRef } from "@converge-collective/common/models/Base";
import { Group } from "@converge-collective/common/models/Group";
import { Network } from "@converge-collective/common/models/Network";
import { Profile } from "@converge-collective/common/models/Profile";
import {
  Button,
  Box,
  Stack,
  TextField,
  Alert,
  AlertTitle,
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { createAlert } from "~/lib/globalAlerts";
import { joinGroup, leaveGroup } from "~/lib/groups";
import { useNetworkMembership } from "~/hooks/useNetworkMembership";
import ConfirmDialog from "../ConfirmDialog";
import { useRouter } from "next/router";
import { groupsRoute, onboardingRoutes } from "~/routes";
import { profileHasName, useLoggedInState } from "~/lib/useLoggedInState";
import { AccountBox } from "@mui/icons-material";

export default function JoinOrLeaveGroupButton({
  network,
  group,
  profile,
}: {
  network: WithRef<Network>;
  group: WithRef<Group>;
  profile: WithRef<Profile>;
}): React.ReactElement {
  const { isNetworkAdmin } = useLoggedInState();
  const { networkMembership } = useNetworkMembership({ profile, network });
  const groupMemberships = networkMembership?.groupMemberships || {};
  const isMember = !isEmpty(groupMemberships[group.id]);
  const router = useRouter();

  const [showEnterCode, setShowEnterCode] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = React.useState(false);
  const [joinCode, setJoinCode] = useState("");
  const isCorrectCode = group.joinCode === joinCode;

  const handleLeaveGroup = () => {
    console.log(network, group, profile, "testingStatus");
    if (group.isPrivate) {
      setIsConfirmDeleteOpen(true);
    } else {
      createAlert({
        message: `You have left the group ${group.name}`,
        severity: "info",
      });
      leaveGroup(network, group, profile.id);
      setShowEnterCode(false);
      setJoinCode("");
      router.push(groupsRoute(network.slug));
    }
  };

  const handleJoinGroup = () => {
    joinGroup(network, group, profile);
    setShowEnterCode(false);
    setJoinCode("");
  };

  const hasName = profileHasName(profile);

  // only network admins can join private groups
  if (!isMember && group.isPrivate && !isNetworkAdmin) return <></>;

  return (
    <Box>
      {isMember ? (
        <Button onClick={handleLeaveGroup} variant="outlined" color="warning">
          Leave Group
        </Button>
      ) : isEmpty(group.joinCode) ? (
        <>
          <Button
            disabled={!hasName}
            onClick={handleJoinGroup}
            variant="contained"
          >
            Join Group
          </Button>
          {!hasName && (
            <Alert sx={{ mt: 1 }} severity="error">
              <AlertTitle>
                Please finish setting up your profile before joining a group
              </AlertTitle>
              <Button
                onClick={() => router.push(onboardingRoutes.account)}
                color="error"
                variant="outlined"
                sx={{ mt: 1 }}
                startIcon={<AccountBox />}
              >
                My Profile
              </Button>
            </Alert>
          )}
        </>
      ) : showEnterCode ? (
        <Stack justifyContent="center">
          <TextField
            label="Enter Code"
            value={joinCode}
            onKeyDown={(e) => {
              if (e.key === "Enter" && isCorrectCode) {
                handleJoinGroup();
              }
            }}
            onChange={(e) => setJoinCode(e.target.value)}
          />
          <Button
            sx={{ mt: 1 }}
            variant="contained"
            disabled={!isCorrectCode}
            startIcon={isCorrectCode ? <LockOpenIcon /> : <LockIcon />}
            onClick={handleJoinGroup}
          >
            Join Group
          </Button>
        </Stack>
      ) : (
        <Button
          onClick={() => setShowEnterCode(true)}
          startIcon={<LockIcon />}
          variant="contained"
        >
          Enter Code to Join
        </Button>
      )}

      <ConfirmDialog
        isOpen={isConfirmDeleteOpen}
        close={() => setIsConfirmDeleteOpen(false)}
        confirmTitle={
          "Are you sure you want to leave this private group? Once you leave, you will no longer be able to see the group or any of its messages."
        }
        confirmButtonTitle="Yes, Leave Group"
        proceedWithAction={async () => {
          leaveGroup(network, group, profile.id);
          setShowEnterCode(false);
          setJoinCode("");
          setIsConfirmDeleteOpen(false);
        }}
        onSuccess={() => {
          router.push(groupsRoute(network.slug));
          createAlert({
            message: `You have left the group ${group.name}`,
            severity: "info",
          });
        }}
      />
    </Box>
  );
}
