import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import { Post } from "@converge-collective/common/models/Post";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  LinearProgress,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { DocumentReference } from "firebase/firestore";
import React, { useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import InfiniteScroll from "react-infinite-scroll-component";
import { default as PostComponent } from "~/components/feed/Post";
import {
  useCombinedPosts,
  useSinglePost,
  useStickyOnlyPosts,
} from "~/hooks/useNewsFeed";
import ErrorFallback from "../ErrorFallback";
import NotFound, { DeletedMessage } from "../NotFound";
import { collectionMap, CreatePost } from "../feed/CreatePost";
import { DoneAll } from "@mui/icons-material";

type NewsfeedProps = {
  network: WithRef<Network>;
  // any doc ref that has a `posts` sub collection
  postsParent: DocumentReference;
  collectionName?: keyof typeof collectionMap;
  allowCreatePost?: boolean;
  allowPostComments?: boolean;
  allowLikePosts?: boolean;
  // postId is optional, because we can either render the entire feed or only
  // render a single post, depending on whether it was passed in
  postId?: string;
  limit?: number;
  isLeader?: boolean;
};

function Newsfeed({
  network,
  postsParent,
  postId,
  limit: _limit,
  allowCreatePost = false,
  allowPostComments = true,
  allowLikePosts = true,
  collectionName,
  isLeader = false,
}: NewsfeedProps) {
  const parentRef = useRef<HTMLInputElement>(null);
  const [isSticky, setIsSticky] = useState(false);

  const renderPost = (post: WithRef<Post>) => {
    // console.log("newsfeed render post", { post });
    if (!post) return <></>;
    return (
      <PostComponent
        sx={{ overflow: "hidden" }}
        allowLikePosts={allowLikePosts}
        allowPostComments={allowPostComments}
        key={post.id}
        isLeader={isLeader}
        postsParent={postsParent}
        network={network}
        post={post}
      />
    );
  };

  return (
    <div ref={parentRef}>
      {/* Create Post Form */}
      {allowCreatePost && (
        <Box>
          <CreatePost
            key={network.id}
            parentRef={postsParent}
            network={network}
            collectionName={collectionName}
          />
        </Box>
      )}

      {/* Sticky Posts Toggler */}
      {!postId && (
        <StickyOnlyToggle isSticky={isSticky} setIsSticky={setIsSticky} />
      )}

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {/* Single Post */}
        {postId && (
          <PostDetailCard
            postId={postId}
            postsParent={postsParent}
            renderPost={renderPost}
          />
        )}

        {/* Sticky Posts only */}
        {!postId && isSticky && (
          <StickyNewsfeed postsParent={postsParent} renderPost={renderPost} />
        )}

        {/* Non Sticky Posts only */}
        {!postId && !isSticky && (
          <NonStickyNewsfeed
            postsParent={postsParent}
            renderPost={renderPost}
          />
        )}
      </ErrorBoundary>
    </div>
  );
}

function NonStickyNewsfeed({
  postsParent,
  renderPost,
}: {
  postsParent: DocumentReference;
  renderPost: (post: WithRef<Post>) => React.JSX.Element;
}) {
  const { fetchPosts, posts, hasMore } = useCombinedPosts(postsParent, true);
  return (
    <>
      <InfiniteScroll
        dataLength={posts.length}
        hasMore={hasMore}
        next={fetchPosts}
        loader={<LinearProgress sx={{ my: 2 }} />}
        scrollableTarget="scrollableDiv"
      >
        {posts.map(renderPost)}
      </InfiniteScroll>

      {!hasMore && <EndHere />}
    </>
  );
}

function StickyNewsfeed({
  postsParent,
  renderPost,
}: {
  postsParent: DocumentReference;
  renderPost: (post: WithRef<Post>) => React.JSX.Element;
}) {
  const { fetchPosts, posts, hasMore } = useStickyOnlyPosts(postsParent, true);
  return (
    <>
      <InfiniteScroll
        dataLength={posts.length}
        hasMore={hasMore}
        next={fetchPosts}
        loader={<LinearProgress sx={{ my: 2 }} />}
        scrollableTarget="scrollableDiv"
      >
        {posts.map(renderPost)}
      </InfiniteScroll>
      {!hasMore && <EndHere />}
    </>
  );
}

function PostDetailCard({
  postId,
  postsParent,
  renderPost,
}: {
  postId: string;
  postsParent: DocumentReference;
  renderPost: (post: WithRef<Post>) => React.JSX.Element;
}) {
  const { post, loading, hasPost, isDeleted } = useSinglePost(
    postsParent,
    postId
  );
  return loading ? (
    <Stack direction="row" justifyContent="center" p={2}>
      <CircularProgress size={30} color="primary" />
    </Stack>
  ) : !hasPost ? (
    <NotFound message="Post not found" />
  ) : isDeleted ? (
    <DeletedMessage message="This post was archived." />
  ) : (
    renderPost(post)
  );
}

function StickyOnlyToggle({
  isSticky,
  setIsSticky,
}: {
  isSticky: boolean;
  setIsSticky: (isSticky: boolean) => void;
}) {
  return (
    <Stack direction="row" justifyContent="flex-end" pb={1}>
      <FormControlLabel
        control={
          <Switch
            checked={isSticky}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setIsSticky(event.target.checked);
            }}
            name="isSticky"
            color="primary"
          />
        }
        label="Show only Pinned Posts"
      />
    </Stack>
  );
}

function EndHere() {
  return (
    <Typography
      component="div"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      variant="caption"
    >
      <DoneAll sx={{ fontSize: "1.2rem", mr: 1 }} />
      You've reached the end
    </Typography>
  );
}

export default React.memo(Newsfeed);
