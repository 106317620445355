import { AccessLevels } from "@converge-collective/common/models/Authz";
import { WithRef } from "@converge-collective/common/models/Base";
import { Doc } from "@converge-collective/common/models/Doc";
import {
  DocStatuses,
  DocUpdate,
  appendDocUpdate,
} from "@converge-collective/common/models/DocMeta";
import {
  DocRequestKinds,
  ReverificationDocRequest,
} from "@converge-collective/common/models/DocRequest";
import { LiteGroup } from "@converge-collective/common/models/Group";
import { Network } from "@converge-collective/common/models/Network";
import {
  DocPost,
  MessagePost,
  POST_TYPE,
} from "@converge-collective/common/models/Post";
import {
  ProfileWithTimestamp,
  liteProfile,
} from "@converge-collective/common/models/Profile";
import {
  Values,
  formatDate,
  formatDateTime,
  shallowTSToDates,
} from "@converge-collective/common/util";
import {
  AddComment,
  ArchiveOutlined,
  Download,
  DriveFileMove,
  Edit,
  Folder,
  Info,
  List,
  PublishedWithChanges,
  VerifiedUser,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Breadcrumbs,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  FormControlLabel,
  IconButton,
  Link as MuiLink,
  Stack,
  Switch,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
  TypographyVariant,
  useTheme,
} from "@mui/material";
import { addDays } from "date-fns";
import {
  addDoc,
  collection,
  doc as firestoreDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import jsPDF from "jspdf";
import { isDate, isEmpty, last } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import { useRef, useState } from "react";
import { useUserAccessLevel } from "~/hooks/useAuthz";
import { useFolder } from "~/hooks/useFolders";
import { AuthorizedActionTypes, canPerformAction } from "~/lib/authz";
import { converters } from "~/lib/converter";
import { createAlert } from "~/lib/globalAlerts";
import { docDetailRoute, docsRoute, folderDetailRoute } from "~/routes";
import AutocompleteGroup from "../AutocompleteGroup";
import ConfirmDialog from "../ConfirmDialog";
import { MoveDocDialog } from "../MoveDocDialog";
import { RenderHTML } from "../RenderHTML";
import { TagChip } from "../TagChip";
import { VisibleForTime } from "../VisibleForTime";
import Newsfeed from "../dashboard/Newsfeed";
import Editor from "../editor/Editor";
import ProfilePreview from "../user/ProfilePreview";
import { DocReverifyRequest } from "./DocReverifyRequest";

import dynamic from "next/dynamic";
import { useIsMobile } from "~/hooks/mobile";
import { useHydratedCurrentNetworkMembership } from "~/hooks/useProfile";
import { urlForPost } from "~/lib/url";
import {
  HydratedLoggedInState,
  withLoggedInState,
} from "~/lib/withLoggedInState";
import { SeenByReadBy } from "../SeenByReadBy";
import { PageViewCounter } from "../analytics/PageViewCounter";
import { ViewStatsCapture } from "../ViewStatsCapture";

// import { RenderOriginalDoc } from "./RenderOriginalDoc";

// import RenderOriginalDoc dynamically in order to avoid ssr
const RenderOriginalDoc = dynamic(() => import("./RenderOriginalDoc"), {
  ssr: false,
});

const VerificationStatuses = {
  Verified: "Verified",
  Unverified: "Unverified",
  NearUnverified: "Near Unverified",
} as const;

type VerificationStatus = Values<typeof VerificationStatuses>;

const verificationStatusColors = (theme: Theme) =>
  ({
    [VerificationStatuses.Verified]: theme.palette.success.dark,
    [VerificationStatuses.Unverified]: theme.palette.error.main,
    [VerificationStatuses.NearUnverified]: theme.palette.warning.light,
  }) as const;

const getVerificationStatus = (doc: Doc): VerificationStatus | undefined => {
  const lastUpdate = doc.latestDocUpdate;
  if (!doc.verificationPeriodDays || !lastUpdate) {
    return undefined;
  }

  if (doc.verificationPeriodDays === -1) {
    return VerificationStatuses.Verified;
  }

  const now = new Date();
  const bufferDays = Math.floor(doc.verificationPeriodDays * 0.1);
  const nearMinDate = addDays(
    lastUpdate.date,
    doc.verificationPeriodDays - bufferDays
  );
  const verifyByDate = addDays(lastUpdate.date, doc.verificationPeriodDays);
  const isNearUnverified = now > nearMinDate && now < verifyByDate;
  const isUnverified = !isNearUnverified && now > verifyByDate;
  const isVerified = !isUnverified && !isNearUnverified;

  return isVerified
    ? VerificationStatuses.Verified
    : isUnverified
      ? VerificationStatuses.Unverified
      : isNearUnverified
        ? VerificationStatuses.NearUnverified
        : undefined;
};

export const DocCard = withLoggedInState(function DocCard({
  doc,
  network,
  profile,
  onEdit,
  isExpanded = false,
}: {
  doc: WithRef<Doc>;
  network: WithRef<Network>;
  /** allow triggering an edit action that the parent handles */
  onEdit?: (doc: WithRef<Doc>, isReverify: boolean) => void;
  isExpanded?: boolean;
} & HydratedLoggedInState): React.ReactElement {
  const userAccessLevel = useUserAccessLevel(doc.authzGrants, profile);
  const isDeleted = doc.latestDocUpdate.status === DocStatuses.Deleted;

  const router = useRouter();
  const theme = useTheme();
  const wasSeenByUser = profile && doc.seenBy?.[profile.id];
  const wasReadByUser = profile && doc.readBy?.[profile.id];

  const networkMembership = useHydratedCurrentNetworkMembership();
  const shouldTrack =
    networkMembership?.network?.id === network.id &&
    networkMembership.role !== "superadmin";

  const [postToGroup, setPostToGroup] = useState(false);
  const [groupToPostTo, setGroupToPostTo] = useState<LiteGroup | null>(null);

  /**
   * isSeen should be set to true if the document was on the screen for 2
   * seconds or more
   */
  const seenMinTime = 2000;
  // we can use this state to trigger a confetti animation
  const [, setIsSeen] = useState(false);
  /**
   * isRead should be set to true if the very bottom of the document was on the
   * screen for 2 seconds or more
   */
  const readMinTime = 2000;
  // we can use this state to trigger a confetti animation
  const [, setIsRead] = useState(false);

  const [shouldRenderReverifyRequest, setShouldRenderReverifyRequest] =
    useState(isExpanded);

  const markAsRead = async () => {
    console.log("attempting to mark as read", { doc, wasReadByUser, profile });
    if (!profile) {
      console.error("Could not find profile", { doc, profile });
      return;
    }
    if (!wasReadByUser) {
      console.log(`marking doc as read - ${doc.name}`);
      const tp: ProfileWithTimestamp = {
        profile: liteProfile(profile),
        timestamp: new Date(),
      };

      // Note: this doesn't seem to type check :(
      await updateDoc(doc.ref.withConverter(converters.doc.write), {
        [`readBy.${profile.id}`]: tp,
      });
    }
  };

  const markAsSeen = async () => {
    console.log("attempting to mark as seen", { doc, wasSeenByUser, profile });
    if (!profile) {
      console.error("Could not find profile", { doc, profile });
      return;
    }
    if (!wasSeenByUser) {
      console.log(`marking doc as seen - ${doc.name}`);

      const tp: ProfileWithTimestamp = {
        profile: liteProfile(profile),
        timestamp: new Date(),
      };

      // Note: this doesn't seem to type check :(
      await updateDoc(doc.ref.withConverter(converters.doc.write), {
        [`seenBy.${profile.id}`]: tp,
      });
    } else {
      console.log(`doc already seen - ${doc.name}`);
    }
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isPostToFeedDialogOpen, setIsPostToFeedDialogOpen] = useState(false);
  const [isMoveDocDialogOpen, setIsMoveDocDialogOpen] = useState(false);
  const [isReverificationDialogOpen, setIsReverificationDialogOpen] =
    useState(false);
  const [reverificationNote, setReverificationNote] = useState("");
  const [note, setNote] = useState("");
  const [groupValidation, setGroupValidation] = useState("");
  const lastUpdate = shallowTSToDates(doc.latestDocUpdate);
  // use a ref for the document html so if the user downloads it it'll include
  // any styles that are applied to the document
  const docHtml = useRef<HTMLInputElement>(null);
  const maxHeight = isExpanded ? "none" : 400;
  const handleDownload = async () => {
    const input = docHtml.current;
    if (!input) {
      console.error("Could not find input");
      return;
    }
    const pdfDoc = new jsPDF();
    pdfDoc.html(input, {
      callback: (pdf) => {
        // Save the PDF
        pdf.save(`${doc.slug}.pdf`);
      },
      margin: [5, 5, 5, 5],
      autoPaging: "text",
      x: 15,
      y: 15,
      width: 170, // target width in the PDF document
      windowWidth: 650, // window width in CSS pixels
    });
  };

  const verifyStatus = getVerificationStatus(doc);
  const verifyColors = verificationStatusColors(theme);
  const verifyColor = verifyStatus ? verifyColors[verifyStatus] : undefined;
  const verifiedUntil =
    lastUpdate?.date &&
    lastUpdate &&
    doc.verificationPeriodDays !== undefined &&
    doc.verificationPeriodDays !== -1 &&
    addDays(lastUpdate.date, doc.verificationPeriodDays);

  console.log("verified until", { verifiedUntil, lastUpdate });
  const verifySeverity =
    verifyStatus === VerificationStatuses.Unverified
      ? "error"
      : verifyStatus === VerificationStatuses.NearUnverified
        ? "warning"
        : verifyStatus === VerificationStatuses.Verified
          ? "success"
          : undefined;

  // we can't look up a folder this way anymore because subfolders
  const folderInfo = useFolder({ network, profile, slug: doc.folderPaths[0] });

  type BreadcrumbItem = {
    name: string;
    icon: JSX.Element;
    href: string;
  };
  // for mobile view
  const isMobile = useIsMobile();

  const folderBreadCrumbs = [
    ...(folderInfo
      ? folderInfo.subFolderInfo
        ? [
            ...folderInfo.subFolderInfo.fullPath.map((n) => ({
              name: n.name,
              icon: <Folder color="primary" />,
              href: folderDetailRoute(network.slug, n.slug),
            })),
            {
              name: folderInfo.subFolderInfo.subFolder.name,
              icon: <Folder color="primary" />,
              href: folderDetailRoute(
                network.slug,
                folderInfo.subFolderInfo.subFolder.slug
              ),
            },
          ]
        : [
            {
              name: folderInfo.root.name,
              icon: <Folder color="primary" />,
              href: folderDetailRoute(network.slug, folderInfo.root.slug),
            },
          ]
      : []),
  ];

  const breadcrumbs: BreadcrumbItem[] = [
    {
      name: "Docs & Files",
      icon: <List color="primary" />,
      href: docsRoute(network.slug),
    },
    ...folderBreadCrumbs,
  ];
  const canEdit = canPerformAction(
    AuthorizedActionTypes.DocsEdit,
    userAccessLevel
  );
  console.log("can edit doc", { canEdit, userAccessLevel });

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ViewStatsCapture
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
        entity={doc.ref}
        url={docDetailRoute(network.slug, doc.slug)}
      >
        {isDeleted && <ArchivedDoc doc={doc} />}
        {verifyColor && (
          <Box
            sx={{
              background: verifyColor,
              height: "8px",
            }}
          />
        )}
        {!isEmpty(doc.coverPhotoUrl) ? (
          <Box
            sx={{
              height: 140,
              backgroundImage: `
            linear-gradient(to bottom, rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.5)),
            url("${doc.coverPhotoUrl}")
          `,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
        ) : null}
        {profile && (
          <VisibleForTime
            durationVisible={seenMinTime}
            onVisibleForTime={() => {
              // console.log(`Visible for time ${doc.name}`, {
              //   doc,
              //   profile,
              //   user,
              // });
              setShouldRenderReverifyRequest(true);
              if (!wasSeenByUser) {
                setIsSeen(true);
                markAsSeen();
              }
            }}
            active={shouldTrack}
          >
            <CardHeader
              sx={{
                "& .MuiCardHeader-content": { overflow: "hidden" },
              }}
              title={
                <>
                  <Breadcrumbs
                    sx={{ mb: 1 }}
                    separator="›"
                    maxItems={2}
                    aria-label="breadcrumb"
                  >
                    {breadcrumbs.map((breadcrumb, index) => (
                      <Link key={index} legacyBehavior href={breadcrumb.href}>
                        <MuiLink
                          href={breadcrumb.href}
                          sx={{ display: "flex", alignItems: "center" }}
                          color="inherit"
                          underline="hover"
                        >
                          {breadcrumb.icon}
                          <div style={{ marginLeft: "4px" }}>
                            {breadcrumb.name}
                          </div>
                        </MuiLink>
                      </Link>
                    ))}
                  </Breadcrumbs>
                  {isExpanded && <PageViewCounter firestoreRef={doc.ref} />}
                  <Stack direction="row" justifyContent="space-between">
                    <Link
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      href={docDetailRoute(network.slug, doc.slug)}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          overflow: "hidden",
                          "&:hover": {
                            background: theme.palette.grey[50],
                            textDecoration: "none",
                          },
                        }}
                      >
                        {doc.name}
                      </Typography>
                    </Link>

                    {canPerformAction(
                      AuthorizedActionTypes.DocsEdit,
                      userAccessLevel
                    ) &&
                      onEdit && (
                        <>
                          <Tooltip title="Edit Doc">
                            <IconButton
                              color="primary"
                              onClick={() => onEdit(doc, false)}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                  </Stack>
                </>
              }
              disableTypography
              subheader={
                <Box>
                  <Stack direction="row" spacing={1} alignItems="center">
                    {lastUpdate && (
                      <>
                        <Typography variant="subtitle1">
                          Last updated <b>{formatDateTime(lastUpdate.date)}</b>
                        </Typography>
                      </>
                    )}
                    <Tooltip
                      title={`You have ${userAccessLevel} access on this doc`}
                    >
                      <Chip
                        icon={<VerifiedUser />}
                        label={` ${userAccessLevel}`}
                        color="primary"
                        variant="outlined"
                        size="small"
                        sx={{
                          mr: 0.5,
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                        }}
                      />
                    </Tooltip>
                  </Stack>
                  {verifyStatus && verifiedUntil && isDate(verifiedUntil) && (
                    <Alert
                      sx={{ my: 1 }}
                      severity={verifySeverity}
                      action={
                        <Tooltip title="Request doc reverification">
                          <IconButton
                            onClick={() => setIsReverificationDialogOpen(true)}
                            size="small"
                            color="inherit"
                          >
                            <PublishedWithChanges color="inherit" />
                          </IconButton>
                        </Tooltip>
                      }
                    >
                      {verifyStatus === VerificationStatuses.Verified && (
                        <>
                          Verified until <b>{formatDate(verifiedUntil)}</b>
                        </>
                      )}
                      {verifyStatus === VerificationStatuses.NearUnverified && (
                        <>
                          Needs re-verification by{" "}
                          <b>{formatDate(verifiedUntil)}</b>
                        </>
                      )}
                      {verifyStatus === VerificationStatuses.Unverified && (
                        <>
                          Unverified since <b>{formatDate(verifiedUntil)}</b>
                        </>
                      )}
                    </Alert>
                  )}
                  {shouldRenderReverifyRequest && (
                    <DocReverifyRequest
                      isExpanded={isExpanded}
                      onEdit={() => {
                        onEdit?.(doc, true);
                      }}
                      doc={doc}
                    />
                  )}
                  <Typography variant="subtitle2">
                    <ProfilePreview profile={doc.createdBy}>
                      Created by <b>{doc.createdBy.name}</b>
                    </ProfilePreview>
                  </Typography>
                  {!isEmpty(doc?.owners) && (
                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      <Typography sx={{ mr: 0.5 }} variant="subtitle2">
                        Owned by
                      </Typography>
                      {doc?.owners?.map((owners, index) => {
                        return (
                          <ProfilePreview
                            profile={owners}
                            key={index}
                            wrapperStyle={{
                              cursor: "pointer",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Chip
                              label={owners.name}
                              color="primary"
                              variant="outlined"
                              size="small"
                              sx={{
                                mr: 0.5,
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                              }}
                            />
                          </ProfilePreview>
                        );
                      })}
                    </Stack>
                  )}
                  <Stack
                    rowGap={1}
                    flexWrap={"wrap"}
                    direction="row"
                    mt={1}
                    spacing={1}
                  >
                    {doc.tags.map((tag) => (
                      <TagChip tag={tag} key={tag.id} />
                    ))}
                  </Stack>
                </Box>
              }
            />
          </VisibleForTime>
        )}
        <CardContent
          sx={{
            maxHeight: maxHeight,
            overflowY: isExpanded ? "visible" : "auto",
            width: "100%",
            p: isMobile ? 1 : 2,
          }}
        >
          <div ref={docHtml}>
            {doc.originalFileUrl && (
              <>
                <RenderOriginalDoc
                  isExpanded={isExpanded}
                  sx={{
                    maxHeight: isExpanded ? "none" : 400,
                    overflowY: "auto",
                  }}
                  doc={doc}
                />
              </>
            )}

            <RenderHTML enableUnfurls={true} html={doc.contentHtml} />
            {profile && (
              <VisibleForTime
                durationVisible={readMinTime}
                onVisibleForTime={() => {
                  setIsRead(true);
                  markAsRead();
                  console.log("Visible for time mark as read", {
                    doc,
                    profile,
                  });
                }}
                active={shouldTrack && !wasReadByUser}
              >
                <Box sx={{ height: "10px" }} />
              </VisibleForTime>
            )}
          </div>
        </CardContent>

        <CardActions
          sx={{
            marginTop: "auto",
            pt: 2,
            flexWrap: "wrap",
          }}
        >
          <SeenByReadBy
            id={doc.id}
            name={`Doc ${doc.name}`}
            seenBy={doc.seenBy}
            readBy={doc.readBy}
          />
          <div style={{ marginLeft: "auto" }} />

          {/* we don't need a dedicated "view doc" icon */}
          {/* {!isExpanded && ( */}
          {/*   <Tooltip title={`View ${docOrJob}`}> */}
          {/*     <IconButton */}
          {/*       LinkComponent={Link} */}
          {/*       href={docDetailRoute(network.slug, doc.slug)} */}
          {/*       color="primary" */}
          {/*     > */}
          {/*       <Article /> */}
          {/*     </IconButton> */}
          {/*   </Tooltip> */}
          {/* )} */}

          {doc?.originalFileUrl ? (
            <Tooltip title="Download File">
              <IconButton href={doc.originalFileUrl} color="primary">
                <Download />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Download PDF">
              <IconButton onClick={handleDownload} color="primary">
                <Download />
              </IconButton>
            </Tooltip>
          )}

          {canPerformAction(
            AuthorizedActionTypes.DocsPostToFeed,
            userAccessLevel
          ) &&
            (doc.authzGrants.access.networkWide.accessLevel ===
            AccessLevels.None ? (
              <Tooltip title="Posting to feeds is disabled for private docs">
                <div>
                  <IconButton disabled>
                    <AddComment />
                  </IconButton>
                </div>
              </Tooltip>
            ) : (
              <Tooltip title="Post to Feed or Group">
                <IconButton onClick={() => setIsPostToFeedDialogOpen(true)}>
                  <AddComment color="primary" />
                </IconButton>
              </Tooltip>
            ))}

          {canPerformAction(
            AuthorizedActionTypes.DocsDelete,
            userAccessLevel
          ) && (
            <Tooltip title="Archive">
              <IconButton
                color="warning"
                onClick={() => setDeleteDialogOpen(true)}
              >
                <ArchiveOutlined />
              </IconButton>
            </Tooltip>
          )}

          {canPerformAction(AuthorizedActionTypes.DocsEdit, userAccessLevel) &&
            onEdit && (
              <>
                <Tooltip title="Edit">
                  <IconButton
                    color="primary"
                    onClick={() => onEdit(doc, false)}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Move Doc to Folder">
                  <IconButton
                    onClick={() => setIsMoveDocDialogOpen(true)}
                    color="primary"
                  >
                    <DriveFileMove />
                  </IconButton>
                </Tooltip>
              </>
            )}

          <ConfirmDialog
            severity="info"
            icon={<PublishedWithChanges />}
            isOpen={isReverificationDialogOpen}
            close={() => setIsReverificationDialogOpen(false)}
            confirmTitle={`Request reverification of the "${
              doc.name
            }" doc. Doc owners will be notified: ${doc.owners
              ?.map((p) => p.name)
              .join(", ")}`}
            confirmButtonTitle={`Request reverification`}
            proceedWithAction={async () => {
              if (!profile) {
                console.error("Could not find profile");
                return;
              }

              const docUpdate: DocUpdate = {
                date: new Date(),
                status: DocStatuses.PendingApproval,
                description: `Reverification requested by ${profile.name}`,
                actor: liteProfile(profile),
              };

              const docRequest: ReverificationDocRequest = {
                kind: DocRequestKinds.Reverification,
                requester: liteProfile(profile),
                docRef: doc.ref,
                note: reverificationNote,
                // a reverification request begins pending approval. once the
                // function is triggered and reverification notifications are sent
                // out, it marks it `Approved`
                latestDocUpdate: docUpdate,
              };

              // create the doc request
              const docRequestRef = await addDoc(
                collection(doc.ref, "docRequests").withConverter(
                  converters.docRequest.write
                ),
                docRequest
              );

              const postLog: DocUpdate = {
                date: new Date(),
                status: DocStatuses.Active,
                description: `${profile.name} created a new post`,
                actor: liteProfile(profile),
              };

              // and also post on the doc feed
              // use the same ID as the docRequest so we can correlate them
              const post: MessagePost = {
                eventType: POST_TYPE.Message,
                network,
                url: docDetailRoute(network.slug, doc.slug),
                description: reverificationNote,
                title: `${profile.name} requested reverification of "${doc.name}" doc`,
                date: new Date(),
                avatar: profile?.photoURL || "",
                profile,
                sticky: false,
                latestDocUpdate: postLog,
              };

              await setDoc(
                firestoreDoc(doc.ref, "posts", docRequestRef.id).withConverter(
                  converters.messagePost.write
                ),
                post
              );
            }}
            onSuccess={() => {
              setReverificationNote("");
              createAlert({
                message: `Reverification requested for "${doc.name}" doc`,
              });
              setIsReverificationDialogOpen(false);
            }}
          >
            <TextField
              value={reverificationNote}
              onChange={(e) => setReverificationNote(e.target.value)}
              label="Note to include with the request, e.g. why are you requesting reverification?"
              fullWidth
              multiline
              minRows={2}
            />
          </ConfirmDialog>

          <MoveDocDialog
            isOpen={isMoveDocDialogOpen}
            onClose={() => setIsMoveDocDialogOpen(false)}
            doc={doc}
          />

          <ConfirmDialog
            severity="info"
            isOpen={isPostToFeedDialogOpen}
            close={() => {
              setGroupValidation("");
              setNote("");
              setGroupToPostTo(null);
              setIsPostToFeedDialogOpen(false);
            }}
            confirmTitle={
              postToGroup
                ? groupToPostTo
                  ? `Post "${doc.name}" to ${groupToPostTo.name} Group`
                  : `Post "${doc.name}" to Group`
                : `Post "${doc.name}" to the Newsfeed`
            }
            confirmButtonTitle={`Post "${doc.name}"`}
            proceedWithAction={async () => {
              if (!profile) {
                throw new Error("Could not find profile");
              }

              if (postToGroup && !groupToPostTo) {
                setGroupValidation("Please select group to post the document.");
                return;
              }

              const parentRef = (
                postToGroup && groupToPostTo
                  ? collection(groupToPostTo.ref, "posts")
                  : collection(network.ref, "posts")
              ).withConverter(converters.docPost.write);

              // console.log(`parent ref ${parentRef.path}`, { parentRef });

              const postId = firestoreDoc(parentRef).id;

              // console.log(`post id ${postId}`, { postId });

              const postRef = firestoreDoc(parentRef, postId).withConverter(
                converters.post.write
              );

              // console.log(`use post ref ${postRef.path}`, { postRef });

              const url = await urlForPost(postRef);
              if (!url) {
                console.error("could not find url for post", { postRef });
                return;
              }

              const postLog: DocUpdate = {
                date: new Date(),
                status: DocStatuses.Active,
                description: `${profile.name} created a new post`,
                actor: liteProfile(profile),
              };
              const docPost: DocPost = {
                eventType: POST_TYPE.Doc,
                sticky: false,
                doc: { ref: doc.ref },
                network,
                url,
                description: note,
                title: doc.name,
                date: new Date(),
                avatar: profile?.photoURL || "",
                profile,
                latestDocUpdate: postLog,
              };

              await setDoc(postRef, docPost);

              const message =
                postToGroup && groupToPostTo
                  ? `"${doc.name}" posted to ${groupToPostTo.name} Group`
                  : `"${doc.name}" posted to Newsfeed`;

              createAlert({ message });
              setGroupToPostTo(null);
              setIsPostToFeedDialogOpen(false);
              setNote("");
            }}
            onSuccess={() => {}}
          >
            <Alert severity="info" icon={false} sx={{ mb: 2 }}>
              <FormControlLabel
                sx={{ ml: 0, mb: 0, pr: 2 }}
                control={
                  <Switch
                    checked={postToGroup}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setPostToGroup(checked);
                      if (!checked) {
                        setGroupValidation("");
                      }
                    }}
                    color="primary"
                  />
                }
                label="Post to group instead of newsfeed"
                labelPlacement="start"
              />
              <Collapse in={postToGroup}>
                <AutocompleteGroup
                  sx={{ mt: 2, width: "100%" }}
                  multiple={false}
                  onSetGroups={(group) => {
                    setGroupToPostTo(group?.[0] || null);
                    setGroupValidation("");
                    console.log("set group", { group });
                  }}
                  helperText="Group to post to"
                  required={false}
                />
              </Collapse>
            </Alert>

            <Editor
              initialHtml={note}
              onChange={(html) => setNote(html)}
              placeholder="Add a optional note to the post..."
              minimalControls={true}
            />

            {groupValidation && (
              <Alert severity="error" sx={{ mt: 2 }}>
                <AlertTitle>{groupValidation}</AlertTitle>
              </Alert>
            )}
          </ConfirmDialog>

          <ConfirmDialog
            isOpen={deleteDialogOpen}
            severity="warning"
            close={() => setDeleteDialogOpen(false)}
            confirmTitle={`Are you sure you want to archive the "${doc.name}" document?`}
            confirmButtonTitle={`Archive "${doc.name}"`}
            proceedWithAction={async () => {
              if (!deleteDialogOpen || !profile) return;

              await updateDoc(
                doc.ref,
                appendDocUpdate(
                  {
                    date: new Date(),
                    status: DocStatuses.Deleted,
                    description: `${profile.name} archived document: ${doc.name}.`,
                    actor: liteProfile(profile),
                  },
                  doc
                )
              );
              setDeleteDialogOpen(false);
              // if they're on the detail page redirect to the docs page
              const { query } = router;
              if (query.slug) {
                router.push(docsRoute(network.slug));
              }
            }}
            onSuccess={() => {
              createAlert({
                message: `"${doc.name}" document deleted`,
              });
            }}
          />
        </CardActions>
        {isExpanded && (
          <Box sx={{ mx: isMobile ? 0 : 4, mt: 2 }}>
            <Newsfeed
              allowCreatePost={true}
              network={network}
              postsParent={doc.ref}
            />
          </Box>
        )}
      </ViewStatsCapture>
    </Card>
  );
});
export function ArchivedDoc({
  doc,
  typographyVariant = "h5",
  sx,
}: {
  doc: Doc;
  typographyVariant?: TypographyVariant;
  sx?: SxProps;
}) {
  return (
    <Backdrop
      sx={{
        ...sx,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        position: "absolute",
        zIndex: "9",
        cursor: "pointer",
        px: 1,
      }}
      open
    >
      <Stack direction="row" alignItems="start" gap={1}>
        <Info color="light" fontSize="medium" />
        <Typography variant={typographyVariant} color="white">
          The <b>{doc.name}</b> doc has been archived.
        </Typography>
      </Stack>
    </Backdrop>
  );
}
