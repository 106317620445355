import { updateDoc, increment, DocumentReference } from "firebase/firestore";
import { useState } from "react";
import { VisibleForTime } from "../VisibleForTime";
import { Box } from "@mui/material";

export function PageViewCounter({
  firestoreRef,
}: {
  firestoreRef: DocumentReference;
}) {
  const [isTracked, setTracked] = useState(false);
  const trackPageView = async () => {
    if (!isTracked) {
      setTracked((curr) => {
        if (!curr) {
          console.log("tracking page view for ref", { firestoreRef });
          // this might be a little sketchy running an async update in a
          // setState ?
          try {
            updateDoc(firestoreRef, {
              pageViewCount: increment(1),
            });
          } catch (e) {
            console.error("error tracking page view", { e, firestoreRef });
          }
        }
        return true;
      });
    }
  };
  return (
    <VisibleForTime
      durationVisible={500}
      onVisibleForTime={trackPageView}
      active={!isTracked}
    >
      <Box sx={{ height: "1px" }} />
    </VisibleForTime>
  );
}
