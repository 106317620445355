import { WithRef } from "@converge-collective/common/models/Base";
import { Post } from "@converge-collective/common/models/Post";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { StaticDateTimePicker } from "@mui/x-date-pickers";
import { addHours } from "date-fns";
import { updateDoc } from "firebase/firestore";
import { useState } from "react";

type ModalProps = {
  post: WithRef<Post>;
  isOpen: boolean;
  close: () => void;
};

export function StickyPostDateModal({ post, isOpen, close }: ModalProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [stickyExpireDate, setStickyExpireDate] = useState<Date>(
    addHours(new Date(), 1)
  );

  async function onSubmit() {
    try {
      await updateDoc(post.ref, {
        sticky: true,
        stickyExpireDate: stickyExpireDate,
      });
      close();
    } catch (error) {
      console.error("Error pinning post", { error });
    }
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={close}
        closeAfterTransition
      >
        <DialogTitle>Pin Post Until</DialogTitle>
        <DialogContent>
          <StaticDateTimePicker
            localeText={{
              toolbarTitle: "",
            }}
            minutesStep={15}
            value={stickyExpireDate}
            onChange={(d: Date) => setStickyExpireDate(d)}
            disablePast
            onAccept={onSubmit}
            slots={{
              actionBar: Actions, // use empty Component as "actionBar" to not render default actions
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

// Since we don't want default actions from datepicker, We use empty Component as "actionBar"
function Actions() {
  return <></>;
}
