import {
  WithCreatedAt,
  WithRef,
} from "@converge-collective/common/models/Base";
import {
  DocStatuses,
  DocUpdate,
} from "@converge-collective/common/models/DocMeta";
import { Group, liteGroup } from "@converge-collective/common/models/Group";
import { Network } from "@converge-collective/common/models/Network";
import {
  MessagePost,
  POST_TYPE,
  Post,
} from "@converge-collective/common/models/Post";
import { liteProfile } from "@converge-collective/common/models/Profile";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Card,
  CardContent,
  Collapse,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { addWeeks, roundToNearestMinutes } from "date-fns";
import {
  DocumentReference,
  collection,
  doc,
  getCountFromServer,
  getDocs,
  query,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { inflect } from "inflection";
import React, { useEffect, useState } from "react";
import { useFirestore } from "reactfire";
import useSWR from "swr";
import { useIsMobile } from "~/hooks/mobile";
import { converters } from "~/lib/converter";
import { urlForPost } from "~/lib/url";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { TZDateTimePicker } from "../TZDateTimePicker";
import Editor from "../editor/Editor";
import { isHtmlContentEmpty } from "~/lib/extractors/htmlContent";
// import { isHtmlContentEmpty } from "~/lib/extractors/content";

export const collectionMap = {
  network: "members",
  group: "groupMembers",
};
const fetchMembersCount = async (
  parentRef: DocumentReference,
  coll: string
) => {
  const countQuery = query(collection(parentRef, coll));
  const countResult = await getCountFromServer(countQuery);
  return countResult.data().count;
};

export function CreatePost({
  parentRef,
  network,
  collectionName,
  isLeader = false,
  group,
}: {
  parentRef: DocumentReference;
  network: WithRef<Network>;
  // when creating a post in a group track the group so we can only show posts
  // for that group during search
  group?: WithRef<Group>;
  isLeader?: boolean;
  collectionName?: keyof typeof collectionMap;
}): React.ReactElement {
  const firestore = useFirestore();
  const [now] = useState(new Date());
  const { profile, isNetworkAdmin } = useLoggedInState();

  const { data: totalMemberCount } = useSWR(
    parentRef && collectionName
      ? ["totalMembersCount", parentRef, collectionName]
      : null,
    () =>
      collectionName
        ? fetchMembersCount(parentRef, collectionMap[collectionName])
        : Promise.resolve(null)
  );

  const stickyDate = () =>
    roundToNearestMinutes(addWeeks(new Date(), 1), {
      nearestTo: 15,
    });

  const [isSmsEnabled, setIsSmsEnabled] = useState(false);
  const [body, setBody] = useState<string>("");
  const [isSticky, setIsSticky] = useState(false);
  const [isEmailNotificationDisabled, setIsEmailNotificationDisabled] =
    useState(false);
  const [stickyExpireDate, setStickyExpireDate] = useState<Date>(stickyDate());

  const [isPosting, setIsPosting] = useState(false);

  useEffect(() => {
    setStickyExpireDate(stickyDate());
  }, [isSticky]);

  const isMobile = useIsMobile();

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (
      event.key === "Enter" &&
      (event.altKey || event.metaKey)
      // !event.shiftKey &&
      // !event.altKey &&
      // !isEmpty(innerText(body))
    ) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (profile) {
      setIsPosting(true);
      const bodyToPost = body.trim();
      // clear body immediately so it feels snappy
      setBody("");
      try {
        const postRef = doc(collection(parentRef, "posts")).withConverter(
          converters.post.write
        );
        const relativeUrl = await urlForPost(postRef);
        if (!relativeUrl) {
          throw new Error("failed to get relative url for post");
        }
        const url = relativeUrl;
        // Saving relative URL because when we add this relative URL to Link tag it redirects to the correct page
        // Also, while copying the link, we have check for relative URL to be copied withBaseURL
        const postLog: DocUpdate = {
          date: new Date(),
          status: DocStatuses.Active,
          description: `${profile.name} created a new post`,
          actor: liteProfile(profile),
        };
        const messagePost: WithCreatedAt<MessagePost> = {
          // always set the createdAt immediately (instead of waiting for a
          // cloud function) to avoid race conditions and allow correct and
          // immediate sorting of the newsfeed. without a `createdAt` field, a
          // post won't even show up in the newsfeed, which makes it seem super
          // slow.
          createdAt: new Date(),
          url,
          network,
          eventType: POST_TYPE.Message,
          date: new Date(),
          sticky: isSticky,
          enableSmsNotification: isSmsEnabled,
          disableEmailNotification: isEmailNotificationDisabled,
          // we are still allowing push notifications when email is disabled. in
          // the future we could add an additional control for this if we want.
          // disablePushNotification: areNotificationsDisabled,
          description: bodyToPost,
          title: "",
          avatar: profile.photoURL || "",
          profile: liteProfile(profile),
          latestDocUpdate: postLog,
          ...(isSticky && { stickyExpireDate }),
          ...(group && { group: liteGroup(group) }),
        };

        // Add subcribers to the post before saving it
        const mentionIds = getMentionsFromHtml(body);
        const subscribers =
          mentionIds?.length > 0 ? [...mentionIds, profile.id] : [profile.id];
        await subscribePostIfNot(subscribers, postRef);

        // Save the post
        await setDoc(postRef, messagePost);
      } catch (e) {
        console.error("failed to post", e);
      } finally {
        setBody("");
        setIsSmsEnabled(false);
        setStickyExpireDate(addWeeks(now, 1));
        setIsSticky(false);
        setIsPosting(false);
      }
    } else {
      console.warn("expected profile to be defined", { profile });
    }
  };

  const subscribePostIfNot = async (
    uids: string[],
    postRef: DocumentReference<Post>
  ) => {
    const subsBatch = writeBatch(firestore);
    const cols = await getDocs(collection(postRef, "subscriptions"));
    uids.forEach((uid) => {
      const document = doc(postRef, "subscriptions", uid);
      const alreadyExists =
        cols.docs.find((doc) => doc.id === uid) !== undefined;
      if (!alreadyExists) {
        subsBatch.set(document, { uid });
      }
    });
    await subsBatch.commit();
    console.log("subscribed to post", uids);
  };

  const getMentionsFromHtml = (html: string) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const elementsWithDataId = tempDiv.querySelectorAll("[data-id]");
    const mentions: string[] = [];
    elementsWithDataId.forEach((element) =>
      mentions.push(element.getAttribute("data-id") as string)
    );
    return mentions;
  };

  if (!profile) {
    return <div />;
  }

  return (
    <Card key={network.id} sx={{ mb: 2, height: "auto", overflow: "unset" }}>
      <CardContent
        sx={{
          "&:last-child": {
            pb: 2,
          },
        }}
      >
        <Stack justifyContent="space-around" onKeyDown={handleKeyDown}>
          <Editor
            stickyOffset={72}
            onChange={(value) => {
              setBody(value);
            }}
            minimalControls={true}
            initialHtml={body}
            placeholder="What do you want to say?"
          />

          <Collapse in={!isHtmlContentEmpty(body)}>
            <Alert sx={{ my: 2 }} icon={false} severity="info">
              <Typography variant="caption" mt={1}>
                {Boolean(totalMemberCount) &&
                totalMemberCount &&
                totalMemberCount > 0 ? (
                  <>
                    This post will notify {totalMemberCount - 1}{" "}
                    {collectionName} {inflect("members", totalMemberCount - 1)}{" "}
                    via{" "}
                    {isSmsEnabled ? (
                      <>
                        push{isEmailNotificationDisabled ? "" : ", email"} and
                        SMS
                      </>
                    ) : (
                      <>push{isEmailNotificationDisabled ? "" : " and email"}</>
                    )}{" "}
                    notifications
                  </>
                ) : (
                  "There are no other members to notify"
                )}
              </Typography>

              {(isNetworkAdmin || isLeader) && (
                <Stack
                  sx={{ mt: 2 }}
                  direction="row"
                  flexWrap={"wrap"}
                  alignItems="center"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isSticky}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setIsSticky(event.target.checked);
                        }}
                        name="isSticky"
                        color="primary"
                      />
                    }
                    label="Pin Post"
                  />
                  {isSticky && (
                    <TZDateTimePicker
                      label="Pin until"
                      minutesStep={15}
                      minDate={now}
                      sx={{ mr: 1 }}
                      value={stickyExpireDate}
                      onChange={(d: Date) => setStickyExpireDate(d)}
                    />
                  )}
                </Stack>
              )}

              <Stack
                justifyContent={"space-between"}
                direction="row"
                flexWrap={"wrap"}
                alignItems="center"
              >
                {(isNetworkAdmin || isLeader) && (
                  <>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isEmailNotificationDisabled}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.checked;
                            setIsEmailNotificationDisabled(value);
                          }}
                          color="primary"
                        />
                      }
                      label="Disable Email Notifications"
                    />
                  </>
                )}
              </Stack>
              {isNetworkAdmin &&
                network.settings?.isSmsNotificationsEnabled && (
                  <Stack direction="row">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isSmsEnabled}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setIsSmsEnabled(event.target.checked);
                          }}
                          name="isSmsEnabled"
                          color="primary"
                        />
                      }
                      label="Enable SMS Notifications"
                    />
                  </Stack>
                )}
            </Alert>

            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                fullWidth={isMobile}
                loading={isPosting}
                onClick={handleSubmit}
                variant="contained"
              >
                Post
              </LoadingButton>
            </Stack>
            {!isMobile && (
              <Typography
                sx={{ textAlign: "right", display: "block", mt: 0.2 }}
                variant="caption"
              >
                <b>Cmd+Enter</b> or <b>Alt+Enter</b> to post
              </Typography>
            )}
          </Collapse>
        </Stack>
      </CardContent>
    </Card>
  );
}
