import { Box, Container, LinearProgress } from "@mui/material";
import Head from "next/head";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSigninCheck } from "reactfire";
import ErrorFallback from "./ErrorFallback";
import MustBeLoggedIn from "./user/MustBeLoggedIn";
import Footer from "./Footer";
import { useIsMobile } from "~/hooks/mobile";

export interface LayoutProps {
  children?: any;
  pageName?: string;
  usePageInTitle?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  disableGutters?: boolean;
  showFooter?: boolean;
  auth?: boolean;
  authFallback?: React.ReactElement;
  // allow a parent element to specify it's still loading in order to avoid the
  // auth popup until loading is complete, since whether to load is often
  // dependent on things like whether it's a public network (which must be
  // loaded).
  isLoading?: boolean;
}
export const defaultProps: LayoutProps = {
  children: undefined,
  pageName: "",
  usePageInTitle: true,
  size: "xl",
  disableGutters: true,
  showFooter: true,
  auth: false,
  isLoading: false,
};

export default function Layout(
  props: LayoutProps = defaultProps
): React.ReactElement {
  const propsWithDefaults = { ...defaultProps, ...props };
  const { status, data: signInCheckResult } = useSigninCheck();
  const isMobile = useIsMobile();
  console.log("Layout", { status, signInCheckResult, propsWithDefaults });

  const title = propsWithDefaults.usePageInTitle
    ? `Converge: ${propsWithDefaults.pageName}`
    : "Converge – Incline Together";
  return (
    <div className={propsWithDefaults.pageName}>
      <Head>
        <meta
          name="description"
          content={`Converge: ${propsWithDefaults.pageName}`}
        />
        <meta
          name="keywords"
          content="workplace, campus, health, progress, growth, social connection, wellness"
        />
        <title>{title}</title>
      </Head>

      <Container
        id="layout-container"
        disableGutters={propsWithDefaults.disableGutters}
        maxWidth={propsWithDefaults.size}
        suppressHydrationWarning={true}
        sx={{ mt: 2, px: 2 }}
      >
        {propsWithDefaults.auth === true ? (
          propsWithDefaults.isLoading || status === "loading" ? (
            <LinearProgress />
          ) : signInCheckResult?.signedIn === true ? (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              {propsWithDefaults.children || <div />}
            </ErrorBoundary>
          ) : (
            <MustBeLoggedIn />
          )
        ) : (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {propsWithDefaults.children || <div />}
          </ErrorBoundary>
        )}
      </Container>
      {propsWithDefaults.showFooter ? (
        <Box>
          <Footer />
        </Box>
      ) : isMobile ? (
        <Box my={5}>
          {/* margin for mobile footer */}
          &nbsp;
        </Box>
      ) : null}
    </div>
  );
}
