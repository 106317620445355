import { WithRef } from "@converge-collective/common/models/Base";
import {
  DocStatus,
  DocStatuses,
} from "@converge-collective/common/models/DocMeta";
import {
  Event,
  EventVisibilities,
} from "@converge-collective/common/models/Event";
import { Network } from "@converge-collective/common/models/Network";
import { formatDate, formatTime } from "@converge-collective/common/util";
import { EventRepeat, GroupWork } from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import { Chip, Stack, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { isPast as isPastDate } from "date-fns";
import { collection, limit, query, where } from "firebase/firestore";
import { isEmpty } from "lodash";
import Link from "next/link";
import React from "react";
import { useFirestoreCollectionData } from "reactfire";
import { RRule } from "rrule";
import useGooglePlace from "~/hooks/useGooglePlace";
import { converters } from "~/lib/converter";
import { eventDetailRoute, eventOccurrenceRoute } from "~/routes";
import { ArchivedEvent } from "../events/ArchivedEvent";
import Img from "../Img";

type Props = {
  event: Event;
  network: WithRef<Network>;
  isCompact?: boolean;
};

export default function EventCard({
  event,
  network,
  isCompact = false,
}: Props): React.ReactElement {
  const { slug: networkSlug } = network;

  const isPast = event?.endAt && isPastDate(event.endAt);

  const shouldFetchGooglePlace = event && isEmpty(event.photoUrls);
  const { googlePlace } = useGooglePlace(
    // conditionally fetch the google place only if the photos are missing
    shouldFetchGooglePlace ? event?.googlePlaceId : undefined,
    // only fetch the photos for the google place
    ["photos"]
  );

  // RRuleSet's toText is buggy so only support a single RRule for now instead
  // of trying to build up a  more complex RRuleSet
  const rrule = !isEmpty(event.rrules)
    ? RRule.fromString(event.rrules[0])
    : null;

  const href =
    rrule && event.startAt
      ? eventOccurrenceRoute(networkSlug, event.slug, event.startAt?.valueOf())
      : eventDetailRoute(networkSlug, event.slug);

  const photoUrl =
    // try to use photos from the database
    (event?.photoUrls?.[0] && event.photoUrls[0]) ||
    // if those don't exist, try from the google place
    (googlePlace && googlePlace.photoUrls?.[0]);

  // Getting latest update od doc
  const latestEventDoc = useFirestoreCollectionData(
    query(
      collection(network.ref, "events").withConverter(converters.event.read),
      where("slug", "==", event.slug),
      limit(1)
    )
  );
  const eventStatus =
    latestEventDoc.status === "success" && latestEventDoc.data.length > 0
      ? latestEventDoc.data[0]?.latestDocUpdate?.status
      : undefined;
  const validStatuses: DocStatus[] = [
    DocStatuses.Published,
    DocStatuses.Active,
  ];
  const isArchived = eventStatus ? !validStatuses.includes(eventStatus) : false;

  return (
    <Link href={href} legacyBehavior>
      <Card
        sx={{
          position: "relative",
          maxWidth: "100%",
          "& p": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
        }}
      >
        {isArchived && <ArchivedEvent event={event} />}
        <CardActionArea
          sx={{
            mb: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            height: "100%",
          }}
        >
          {photoUrl && (
            <Img
              style={{
                objectFit: "cover",
                height: isCompact ? 120 : 300,
                width: "100%",
              }}
              src={photoUrl}
              size={600}
            />
          )}
          <CardContent sx={{ flex: 1 }}>
            <Stack alignItems="center" direction="row" spacing={0.5}>
              {event.visibility === EventVisibilities.Private ? (
                <Tooltip title="Group Event">
                  <GroupWork />
                </Tooltip>
              ) : null}

              {rrule && (
                <Tooltip title={`This event repeats ${rrule.toText()}`}>
                  <EventRepeat />
                </Tooltip>
              )}
              <Typography variant="subtitle1" style={{ lineHeight: "1rem" }}>
                {event.title}
              </Typography>
            </Stack>

            {!isEmpty(event.providers) ? (
              <Typography variant="subtitle2">
                {event.providers?.map((p) => (
                  <React.Fragment key={p}>{p}</React.Fragment>
                ))}
              </Typography>
            ) : null}

            {isEmpty(event.cost) ? (
              <div />
            ) : (
              <Typography
                variant="subtitle2"
                style={{ marginBottom: 0, paddingBottom: 0 }}
                noWrap
              >
                {event.cost}
              </Typography>
            )}

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexWrap={"wrap"}
              mt={1}
            >
              {event.startAt ? (
                <Typography variant="body2">
                  <b>{event.startAt <= new Date() ? "Started" : "Starts"}: </b>

                  <span>
                    {`${formatDate(event.startAt)} at ${formatTime(event.startAt)}`}
                  </span>
                </Typography>
              ) : null}
              {event.endAt ? (
                <Typography variant="body2">
                  <b> Ends: </b>
                  <span>
                    {`${formatDate(event.endAt)} at ${formatTime(event.endAt)}`}
                  </span>
                </Typography>
              ) : null}

              {isPast && (
                <Chip sx={{ mt: 1 }} label="Past Event" size="small" />
              )}
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}
