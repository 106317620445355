import { FallbackProps } from "react-error-boundary";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: FallbackProps): React.ReactElement {
  console.log("ErrorFallback", { error });
  console.error(error);

  if (error.code === "permission-denied") {
    return (
      <Alert severity="error" sx={{ position: "relative" }}>
        <Typography>Unauthorized</Typography>
        <Typography variant="caption">
          Looks like you are not authorized to access the network content.
          Please try again later.{" "}
        </Typography>
        <Button
          size="small"
          color="error"
          variant="contained"
          sx={{ py: 0, pt: 0.5, position: "absolute", right: 20, top: 15 }}
          onClick={resetErrorBoundary}
        >
          Try again
        </Button>
      </Alert>
    );
  }

  return (
    <Alert severity="error" sx={{ position: "relative" }}>
      <AlertTitle>Something went wrong</AlertTitle>
      <pre>{error.message}</pre>
      <Button
        size="small"
        color="error"
        variant="contained"
        sx={{ py: 0, pt: 0.5, position: "absolute", right: 20, top: 15 }}
        onClick={resetErrorBoundary}
      >
        Try again
      </Button>
    </Alert>
  );
}
