import {
  ActivityLog,
  ActivityLogKinds,
  CategoryActivityLog,
} from "@converge-collective/common/models/ActivityLog";
import { WithRef } from "@converge-collective/common/models/Base";
import {
  DocStatuses,
  DocUpdate,
  appendDocUpdate,
} from "@converge-collective/common/models/DocMeta";
import { Network } from "@converge-collective/common/models/Network";
import {
  ActivityPost,
  MessagePost,
  POST_TYPE,
  Post,
} from "@converge-collective/common/models/Post";
import {
  LiteProfile,
  ProfileWithTimestamp,
  liteProfile,
  nanoProfile,
} from "@converge-collective/common/models/Profile";
import { Props } from "@converge-collective/common/models/Props";
import { Subscription } from "@converge-collective/common/models/Subscription";
import {
  formatDate,
  formatDateTime,
  formatTime,
  shallowTSToDates,
} from "@converge-collective/common/util";
import {
  AlarmOff,
  Check,
  Comment,
  ContentCopy,
  PinOutlined,
  ThumbUpAlt,
  ThumbUpAltOutlined,
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  Alert,
  AlertTitle,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Fade,
  Grid2,
  IconButton,
  ImageList,
  ImageListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Link as MuiLink,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import AvatarGroup from "@mui/material/AvatarGroup";
import { SxProps, Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import cx from "clsx";
import {
  DocumentReference,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { inflect } from "inflection";
import { filter, isEmpty, range, take } from "lodash";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import {
  useFirestore,
  useFirestoreDoc,
  useFirestoreDocData,
  useUser,
} from "reactfire";
import useSWR from "swr";
import EventCard from "~/components/activity/EventCard";
import FeedResults from "~/components/dashboard/FeedResults";
import { PostCommentComponent } from "~/components/dashboard/PostComment";
import { useIsMobile } from "~/hooks/mobile";
import useProfile, {
  useHydratedCurrentNetworkMembership,
} from "~/hooks/useProfile";
import { innerText } from "~/lib/browser-util";
import { converters } from "~/lib/converter";
import { totalCommentCountQuery } from "~/lib/swrQueries";
import { withBaseUrl } from "~/lib/url";
import { useLoggedInState } from "~/lib/useLoggedInState";
import AutoLink from "../AutoLink";
import { RenderHTML } from "../RenderHTML";
import { SeenByReadBy } from "../SeenByReadBy";
import { VisibleForTime } from "../VisibleForTime";
import Wrap from "../Wrap";
import { LazyChallengeV2Card } from "../documents/LazyChallengeV2Card";
import { LazyDocCard } from "../documents/LazyDocCard";
import Editor from "../editor/Editor";
import GroupCard from "../groups/GroupCard";
import ProfilePreview from "../user/ProfilePreview";
import UserAvatar from "../user/UserAvatar";
import AddPostPhotos from "./AddPostPhotos";
import DailyPointsCircles from "./DailyPointsCircles";
import Media from "./Media";
import { StickyPostDateModal } from "./StickyPostDateModal";
import { ViewStatsCapture } from "../ViewStatsCapture";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "auto",
      marginBottom: theme.spacing(2),
    },
    sticky: {
      borderWidth: 1,
      borderColor: theme.palette.secondary.main,
    },
    liked: {
      paddingLeft: 10,
      paddingRight: 5,
    },
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: 10,
    },
    commentIcons: {
      marginLeft: "auto",
    },
  })
);

const READ_TIME = 3000;
const SEEN_TIME = 1500;

export default function PostComponent({
  post,
  network,
  sx,
  isLeader = false,
  allowPostComments = true,
  allowLikePosts = true,
}: {
  post: WithRef<Post>;
  network: WithRef<Network>;
  // any doc ref that has a `posts` sub collection
  postsParent: DocumentReference;
  sx?: SxProps;
  isLeader?: boolean;
  allowPostComments?: boolean;
  allowLikePosts?: boolean;
}): React.ReactElement {
  const classes = useStyles();
  const firestore = useFirestore();
  const [now] = useState(new Date());
  const { dailyPointsCap } = network.settings || {};
  const [isEditingDesc, setIsEditingDesc] = useState(false);
  const [description, setDescription] = useState(post.description);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const propsRef = collection(post.ref, "props").withConverter(
    converters.props.read
  );

  useEffect(() => {
    setDescription(post.description);
  }, [post.description]);

  // always fetch the real profile to prevent copying from denormalized docs
  const { data: user } = useUser();
  const [profile] = useProfile(user?.uid);
  // individual prop for current user
  const propRef = doc(propsRef, profile?.id || "noop").withConverter(
    converters.props.write
  );

  // the individual prop that this user gave the post
  const { data: myProps } = useFirestoreDocData<Props>(propRef, {
    idField: "id",
  });

  const wasSeenByUser = user && post.seenBy?.[user.uid];
  const wasReadByUser = user && post.readBy?.[user.uid];
  const networkMembership = useHydratedCurrentNetworkMembership();
  const shouldTrack =
    networkMembership?.network.id === network.id &&
    networkMembership.role !== "superadmin";

  const [isDeleted, setIsDeleted] = useState(false);

  const isMyPost = user?.uid === post.profile.id;

  const { isNetworkAdmin } = useLoggedInState();

  const gaveProps = !!myProps?.profile;

  const handlePropsClick = async () => {
    // toggle create/delete
    console.log("handlePropsClick", { gaveProps });
    if (profile && !gaveProps) {
      // create props for this post
      console.log("creating props");
      await setDoc(propRef, {
        profile: liteProfile(profile),
        date: serverTimestamp(),
      });
    } else {
      console.log("removing props");
      // delete
      await deleteDoc(propRef);
    }
  };

  const handleExpandClick = () => {
    setCommentsExpanded(!commentsExpanded);
  };

  const isValidate = () => {
    const containsContent = (html: string) => {
      const text = innerText(html).trim();
      const imgRegex = /<img\s[^>]*src="[^"]*"[^>]*>/i;
      const videoRegex = /<video\s[^>]*src="[^"]*"[^>]*>/i;
      return text.length > 0 || imgRegex.test(html) || videoRegex.test(html);
    };

    const errs = [...(!containsContent(description) ? ["description"] : [])];

    setValidationErrors(errs);
    return errs;
  };
  // props with current user removed so we can speed up the rendering of
  // giving/removing props
  const props = filter(post.props || [], (p) => p.profile.id != profile?.id);
  const propsCount = props.length + (gaveProps ? 1 : 0);
  const mpr = post as MessagePost;

  const isStickyExpired =
    mpr.sticky && mpr.stickyExpireDate && mpr.stickyExpireDate < now;
  const isStickyActive =
    mpr.sticky && (!mpr.stickyExpireDate || mpr.stickyExpireDate > now);

  const rootClass =
    isStickyActive || isStickyExpired
      ? cx(classes.root, classes.sticky)
      : classes.root;

  const stickyTooltipText = mpr.stickyExpireDate
    ? isStickyActive
      ? `Pinned till ${formatDateTime(mpr.stickyExpireDate)}`
      : isStickyExpired
        ? `Pinned post expired ${formatDateTime(mpr.stickyExpireDate)}`
        : ""
    : "";
  const stickyTooltip = (isStickyExpired || isStickyActive) && (
    <Tooltip placement="left" arrow title={stickyTooltipText}>
      {isStickyExpired ? (
        <AlarmOff sx={{ mt: 0.7, ml: 1.3, mr: 1 }} color="secondary" />
      ) : (
        <ScheduleIcon sx={{ mt: 0.7, ml: 1.3, mr: 1 }} color="secondary" />
      )}
    </Tooltip>
  );

  const toggleEditDesc = () => {
    handleMenuClose();
    setIsEditingDesc(true);
  };

  const [countPhotosLoading, setCountPhotosLoading] = useState(0);
  const [fileValidateError, setFileValidateError] = useState<
    string | undefined
  >(undefined);
  const [isPinned, setPinned] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuClose = () => setAnchorEl(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isUrlAbsolute = (url: string): boolean => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";
    } catch (error) {
      return false;
    }
  };

  const handleDelete = async () => {
    if (!profile) {
      console.log("No Profile so cant delete this post", { post });
      return;
    }

    if (post.eventType === POST_TYPE.PointsEarned) {
      const activityLog = (post as ActivityPost).activityLog;
      const activityLogWithRef: WithRef<ActivityLog> = {
        ...activityLog,
        ref: doc(profile.ref, "activityLogs", post.activityLog.id),
      };
      console.log("delete activity", { activityLogWithRef });
      // TODO why doesn't this work? it throws:
      // FirebaseError: Type does not match the expected instance. Did you pass
      // a reference from a different Firestore SDK?
      // deleteActivity(activityLogWithRef);
      deleteDoc(activityLogWithRef.ref);
    } else {
      console.warn(
        "unable to delete - either profile is missing or post type is unexpected",
        {
          profile,
          post,
        }
      );
    }
    // regardless of the type of post, always delete it from the feed.
    // deleteDoc(post.ref);
    const docUpdate: DocUpdate = {
      status: DocStatuses.Deleted,
      description: `${profile?.name} deleted this post ${post.title}`,
      actor: nanoProfile(profile),
      date: new Date(),
    };
    updateDoc(post.ref, appendDocUpdate(docUpdate, post));
    setIsDeleted(true);
    handleMenuClose();
  };

  const handleSticky = async () => {
    if (!profile) {
      console.warn(
        "unable to update - either profile is missing or post type is unexpected",
        {
          profile,
          post,
        }
      );
      return;
    }

    if (isStickyActive) {
      try {
        await updateDoc(post.ref, {
          sticky: false,
          stickyExpireDate: undefined,
        });
      } catch (error) {
        console.error("Cannot Update Post!");
      }
    } else {
      setPinned((p) => !p);
    }
  };

  const subscribersRef = collection(post.ref, "subscriptions").withConverter(
    converters.subscription.read
  );
  const userSubRef = (
    user ? doc(subscribersRef, user.uid) : doc(firestore, "noop/noop")
  ).withConverter(converters.subscription.write);
  const { data: userSub } = useFirestoreDoc<Subscription>(userSubRef);
  const isSubscribed = userSub?.exists();

  const handleToggleNotifications = async () => {
    if (!user || !profile) {
      console.error("expected user and profile to be defined", {
        user,
        profile,
      });
      return;
    }
    // determine whether the user is already subscribed
    if (isSubscribed) {
      deleteDoc(userSubRef);
    } else {
      setDoc(userSubRef, {
        profile: nanoProfile(profile),
        createdAt: new Date(),
        uid: user.uid,
      });
    }
  };

  const addPhotosAction = isMyPost && profile && (
    <AddPostPhotos
      setCountLoading={setCountPhotosLoading}
      post={post}
      profile={profile}
      onFileUploadError={(e) => setFileValidateError(e)}
    />
  );

  const editPostAction = (isMyPost || isNetworkAdmin) && (
    <MenuItem key="edit" onClick={toggleEditDesc}>
      <ListItemIcon>
        <EditIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>
        Edit Post
        {!isMyPost && isNetworkAdmin && (
          <Chip
            sx={{ ml: 1 }}
            label="Admin"
            variant="outlined"
            color="error"
            size="small"
          />
        )}
      </ListItemText>
    </MenuItem>
  );

  const deleteActivityAction = (isMyPost || isNetworkAdmin) && (
    <MenuItem key="delete" onClick={handleDelete}>
      <ListItemIcon>
        <DeleteIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>
        {post.eventType === POST_TYPE.PointsEarned
          ? "Delete Activity"
          : "Archive Post"}
        {!isMyPost && isNetworkAdmin && (
          <Chip
            sx={{ ml: 1 }}
            label="Admin"
            variant="outlined"
            color="error"
            size="small"
          />
        )}
      </ListItemText>
    </MenuItem>
  );
  const stickyAction = (isNetworkAdmin || isLeader) && (
    <MenuItem key="pin" onClick={handleSticky}>
      <ListItemIcon>
        <PinOutlined fontSize="small" />
      </ListItemIcon>
      <ListItemText>
        {isStickyActive ? "Unpin Post" : "Pin Post"}
        <Chip
          sx={{ ml: 1 }}
          label="Admin"
          variant="outlined"
          color="error"
          size="small"
        />
      </ListItemText>
    </MenuItem>
  );
  const editSubscription = (
    <MenuItem key="editSub" onClick={handleToggleNotifications}>
      <ListItemIcon>
        <NotificationsIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>
        Turn {isSubscribed ? "off" : "on"} notifications
      </ListItemText>
    </MenuItem>
  );

  const [wasCopied, setWasCopied] = useState(false);
  const copyPostUrl = (
    <MenuItem
      key="copyUrl"
      onClick={async () => {
        const absoluteUrl = isUrlAbsolute(post.url)
          ? post.url
          : withBaseUrl(post.url);
        setWasCopied(true);
        await navigator.clipboard.writeText(absoluteUrl);
        setTimeout(() => setWasCopied(false), 2000);
      }}
    >
      <ListItemIcon>
        {wasCopied ? (
          <Check fontSize="small" color="primary" />
        ) : (
          <ContentCopy fontSize="small" />
        )}
      </ListItemIcon>
      <ListItemText>Copy Post URL</ListItemText>
    </MenuItem>
  );
  const cardHeaderAction = (
    <Stack direction="row" justifyContent="flex-end" sx={{ mt: 0, mb: 0 }}>
      <>
        <Box>
          <IconButton size="small" onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={menuOpen}
          onClose={handleMenuClose}
          TransitionComponent={Fade}
        >
          {editSubscription}
          {[editPostAction, deleteActivityAction, stickyAction]}
          {copyPostUrl}
        </Menu>
        {addPhotosAction}
      </>
      {stickyTooltip}
    </Stack>
  );

  const commentsRef = collection(post.ref, "comments").withConverter(
    converters.postComment.read
  );

  // only fetch the number of comments to determine whether comments should be
  // expanded
  const { mutate: mutateTotalCommentCount, data: totalCommentCount = 0 } =
    useSWR(
      totalCommentCountQuery(post.ref.id),
      async () => (await getCountFromServer(commentsRef)).data().count
    );

  // use the minimal display mode for posts with no photos
  const isMinimal = isEmpty(post.photoUrls);

  // if the user can't comment and there aren't any comments, just hide the
  // expand/collapse button altogether
  const showExpandCollapseButton = totalCommentCount > 0 || allowPostComments;
  const [commentsExpanded, setCommentsExpanded] = useState(
    totalCommentCount > 0 || !isMinimal
  );

  useEffect(() => {
    setCommentsExpanded(totalCommentCount > 0 || !isMinimal);
  }, [totalCommentCount, isMinimal]);

  const isMobile = useIsMobile();

  const tooltipRow = (profile: LiteProfile) => (
    <Stack sx={{ mb: 1 }} alignItems="center" direction="row">
      <UserAvatar
        sx={{
          mr: 0.5,
          width: 28,
          height: 28,
          border: "0px solid #777",
        }}
        profile={profile}
      />
      {profile.name}
    </Stack>
  );

  const propsTooltip = (
    <Box sx={{ mt: 1 }}>
      {props &&
        props.map((prop: Props) => (
          <div key={prop.profile.id}>{tooltipRow(prop.profile)}</div>
        ))}
      {gaveProps && profile && tooltipRow(profile)}
    </Box>
  );

  const updateDescription = async (description: string) => {
    if (isValidate().length > 0) {
      return;
    }
    updateDoc(post.ref, { description });

    if (profile && post.eventType === POST_TYPE.PointsEarned) {
      const activityId = post.activityLog.id;
      const activityRef = doc(
        firestore,
        "profiles",
        profile.id,
        "activityLogs",
        activityId
      ).withConverter(converters.activityLog.write);
      updateDoc(activityRef, {
        description,
      });
    }
    setIsEditingDesc(false);
  };

  const markAsSeen = async () => {
    if (!profile) {
      console.error("Could not find profile", { post, profile });
      return;
    }

    if (!wasSeenByUser) {
      const tp: ProfileWithTimestamp = {
        profile: nanoProfile(profile),
        timestamp: new Date(),
      };
      await updateDoc(post.ref.withConverter(converters.post.write), {
        [`seenBy.${profile.id}`]: tp,
      });
    } else {
      // console.log(`post already seen - ${post.title}`);
    }
  };

  const markAsRead = async () => {
    if (!profile) {
      console.error("Could not find profile", { post, profile });
      return;
    }
    if (!wasReadByUser) {
      const tp: ProfileWithTimestamp = {
        profile: nanoProfile(profile),
        timestamp: new Date(),
      };

      await updateDoc(post.ref.withConverter(converters.post.write), {
        [`readBy.${profile.id}`]: tp,
      });
    }
  };

  if (post.description === undefined || post.date === undefined) {
    return <></>;
  }

  return (
    <Collapse sx={sx} in={!isDeleted} unmountOnExit>
      <Card className={rootClass}>
        <VisibleForTime
          durationVisible={SEEN_TIME}
          onVisibleForTime={markAsSeen}
          active={shouldTrack}
        >
          <CardHeader
            avatar={<UserAvatar profile={post.profile} />}
            action={cardHeaderAction}
            title={
              post.eventType === POST_TYPE.PointsEarned ? (
                <ProfilePreview team={post.team} profile={post.profile} />
              ) : (
                <ProfilePreview profile={post.profile} />
              )
            }
            subheader={
              <Stack direction="row" alignItems="center">
                {post.date && (
                  <Wrap
                    condition={Boolean(post.url)}
                    wrapper={(children) => (
                      <MuiLink
                        sx={{
                          color: "grey.600",
                          textDecoration: "none",
                        }}
                        component={Link}
                        href={post.url}
                      >
                        {children}
                      </MuiLink>
                    )}
                  >
                    <>
                      {formatDate(post.date)} at {formatTime(post.date)}
                    </>
                  </Wrap>
                )}
                {post.eventType === POST_TYPE.PointsEarned &&
                  post.activityLog.kind ===
                    ActivityLogKinds.CategoryActivityLog &&
                  (post.activityLog as CategoryActivityLog).sourceName && (
                    <Tooltip
                      sx={{ ml: 0.5 }}
                      title={`Synced via ${post.activityLog.sourceName}`}
                      placement="top"
                      arrow
                    >
                      <VerifiedIcon color="secondary" fontSize="small" />
                    </Tooltip>
                  )}
              </Stack>
            }
          />
        </VisibleForTime>

        <CardContent sx={{ pt: 0, pb: 0 }}>
          <ViewStatsCapture entity={post.ref} url={post.url}>
            {!isEmpty(post.title) && post.eventType !== POST_TYPE.Doc && (
              <Typography variant="subtitle2">
                <AutoLink text={post.title} />
              </Typography>
            )}
            {(isMyPost || isNetworkAdmin) && isEditingDesc ? (
              <>
                <Editor
                  initialHtml={description}
                  onChange={(value) => {
                    setDescription(value);
                  }}
                />

                <Stack justifyContent="right" direction="row" sx={{ mt: 2 }}>
                  <Button
                    onClick={() => {
                      setIsEditingDesc(false);
                      setValidationErrors([]);
                      setDescription(post.description);
                    }}
                    variant="outlined"
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      updateDescription(description);
                    }}
                    variant="contained"
                  >
                    Save
                  </Button>
                </Stack>
              </>
            ) : (
              <RenderHTML
                sx={{
                  "& video": {
                    maxHeight: 500,
                  },
                }}
                enableUnfurls
                enableImageGrid
                html={post.description}
              />
            )}
            {validationErrors.length > 0 && (
              <Alert severity="error" sx={{ width: "100%", mb: 2, mt: 2 }}>
                <AlertTitle>Please enter required fields:</AlertTitle>

                <ul>
                  {validationErrors.map((v) => (
                    <li key={v}>{v}</li>
                  ))}
                </ul>
              </Alert>
            )}
            {countPhotosLoading > 0 && (
              <ImageList cols={Math.min(3, countPhotosLoading)} gap={4}>
                {range(countPhotosLoading).map((index) => (
                  <ImageListItem key={index}>
                    <Skeleton
                      sx={{ width: "100%", height: 200 }}
                      key={index}
                      animation="wave"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            )}
            {post.photoUrls && (
              <ImageList cols={Math.min(3, post.photoUrls.length)} gap={4}>
                {post.photoUrls.map((photoUrl, idx) => (
                  <ImageListItem key={idx}>
                    <Media src={photoUrl} />
                  </ImageListItem>
                ))}
              </ImageList>
            )}
            {fileValidateError && !isEmpty(fileValidateError) && (
              <Alert sx={{ mt: 2 }} severity="error">
                <AlertTitle>{fileValidateError}</AlertTitle>
              </Alert>
            )}
            {dailyPointsCap &&
              post.eventType === POST_TYPE.PointsEarned &&
              post.dailyPoints && (
                <Stack sx={{ mb: 1 }} direction="row" justifyContent="center">
                  <DailyPointsCircles
                    dailyPoints={post.dailyPoints.map(shallowTSToDates)}
                  />
                </Stack>
              )}
            {post.eventType === POST_TYPE.PointsEarned && (
              <FeedResults post={post as ActivityPost} />
            )}
            {network && post.eventType === POST_TYPE.EventCreated && (
              <Box
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="center"
              >
                <EventCard
                  network={network}
                  event={shallowTSToDates(post.event)}
                />
              </Box>
            )}
            {network && post.eventType === POST_TYPE.GroupCreated && (
              <Box my={1}>
                <GroupCard
                  dense={true}
                  network={network}
                  group={shallowTSToDates(post.group)}
                />
              </Box>
            )}
            {network &&
              profile?.currentNetworkMembership &&
              post.eventType === POST_TYPE.Doc && (
                <Grid2
                  container
                  mt={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <LazyDocCard
                    network={network}
                    doc={shallowTSToDates(post.doc)}
                  />
                </Grid2>
              )}
            {network && post.eventType === POST_TYPE.ChallengeV2 && (
              <Grid2
                container
                mt={2}
                alignItems="center"
                justifyContent="center"
              >
                <LazyChallengeV2Card
                  network={network}
                  challengeV2={post.challengeV2}
                />
              </Grid2>
            )}
          </ViewStatsCapture>
        </CardContent>

        <CardActions sx={{ pt: 0 }} disableSpacing>
          <Stack sx={{ ml: 2, my: 1 }} width="100%" direction="row" spacing={1}>
            {/* hide this on mobile */}
            {!isMobile && (
              <SeenByReadBy
                id={post.id}
                name={`Post by ${post.profile.name}`}
                isAdminOnly={false}
                seenBy={post.seenBy}
                readBy={post.readBy}
              />
            )}
          </Stack>

          {(!isEmpty(props) || gaveProps) && (
            <>
              <div className={classes.liked}>
                <AvatarGroup
                  total={propsCount}
                  classes={{ avatar: classes.small }}
                  max={isMobile ? 1 : 4}
                >
                  {take(props || [], 4).map((prop, idx) => (
                    <UserAvatar key={idx} profile={prop.profile} />
                  ))}
                  {gaveProps && profile && <UserAvatar profile={profile} />}
                </AvatarGroup>
              </div>
              <Tooltip arrow title={propsTooltip}>
                <Stack sx={{ mr: 1 }} alignItems="center" direction="row">
                  <ThumbUpAlt fontSize="inherit" sx={{ mr: 0.5 }} />
                  {propsCount.toString()}
                </Stack>
              </Tooltip>
            </>
          )}

          <Stack
            alignItems="center"
            spacing={1}
            justifySelf={"flex-end"}
            justifyContent="flex-end"
            direction="row"
            className={classes.commentIcons}
          >
            {allowLikePosts && (
              <Tooltip title={gaveProps ? "Remove props" : "Give props"}>
                {propsCount > 0 ? (
                  <IconButton
                    aria-label="like"
                    onClick={handlePropsClick}
                    size="medium"
                    color="primary"
                  >
                    {gaveProps ? (
                      <ThumbUpAlt fontSize="inherit" />
                    ) : (
                      <ThumbUpAltOutlined fontSize="inherit" />
                    )}
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="like"
                    onClick={handlePropsClick}
                    size="medium"
                    color="primary"
                  >
                    <ThumbUpAltOutlinedIcon
                      fontSize="inherit"
                      color={gaveProps ? "primary" : "inherit"}
                    />
                  </IconButton>
                )}
              </Tooltip>
            )}
            {/* if there are 0 comments and user doesn't have permission to reply, hide this */}
            {showExpandCollapseButton && (
              <Tooltip
                title={`${totalCommentCount} top level ${inflect("comment", totalCommentCount)}`}
                arrow
              >
                <IconButton
                  onClick={handleExpandClick}
                  aria-label="show comments"
                >
                  <Badge
                    sx={{
                      "& .MuiBadge-badge": {
                        fontSize: 8,
                        padding: 0,
                        height: 15,
                        minWidth: 15,
                      },
                    }}
                    badgeContent={totalCommentCount}
                    color="secondary"
                  >
                    <Comment
                      fontSize="inherit"
                      color={commentsExpanded ? "primary" : "inherit"}
                    />
                  </Badge>
                </IconButton>
              </Tooltip>
            )}
            {isNetworkAdmin && post.enableSmsNotification && (
              <Tooltip title="This post sent SMS notifications">
                <Chip
                  label="SMS"
                  size="small"
                  color="info"
                  variant="outlined"
                />
              </Tooltip>
            )}
          </Stack>

          {profile && (
            <VisibleForTime
              durationVisible={READ_TIME}
              onVisibleForTime={markAsRead}
              active={shouldTrack && !wasReadByUser}
            >
              <Box sx={{ height: "1px" }} />
            </VisibleForTime>
          )}
        </CardActions>

        <Collapse in={commentsExpanded} timeout="auto" unmountOnExit>
          <Box sx={{ px: 2, pb: 2, maxWidth: "100%", overflowX: "hidden" }}>
            {user && (
              <PostCommentComponent
                allowPostComments={allowPostComments}
                allowLikePosts={allowLikePosts}
                totalCommentCount={totalCommentCount}
                mutateTotalCommentCount={mutateTotalCommentCount}
                post={post}
                network={network}
              />
            )}
          </Box>
        </Collapse>

        {isPinned && (
          <StickyPostDateModal
            isOpen={isPinned}
            close={() => setPinned(false)}
            post={post}
          />
        )}
      </Card>
    </Collapse>
  );
}
