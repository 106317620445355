import { Chip, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

export function AdminChip({
  size = "small",
  children,
}: {
  size?: "small" | "medium";
  children: React.ReactNode;
}) {
  return (
    <Chip
      variant="outlined"
      size={size}
      color="error"
      label={
        <Stack direction="row" alignItems="center" spacing={1}>
          <Tooltip arrow title="Only Network Admins can see this">
            <span style={{ cursor: "default" }}>Admin</span>
          </Tooltip>
          {children}
        </Stack>
      }
    />
  );
}
