import { WithRef } from "@converge-collective/common/models/Base";
import { Group } from "@converge-collective/common/models/Group";
import {
  Profile,
  liteProfile,
} from "@converge-collective/common/models/Profile";
import React, { useState } from "react";
import ConfirmDialog from "../ConfirmDialog";
import { createAlert } from "~/lib/globalAlerts";
import { Box } from "@mui/material";
import {
  DocStatuses,
  DocUpdate,
  appendDocUpdate,
} from "@converge-collective/common/models/DocMeta";
import { updateDoc } from "firebase/firestore";

type ArchiveProps = React.PropsWithChildren<{
  group: WithRef<Group>;
  profile: WithRef<Profile>;
}>;
export function AchiveGroupAction({ group, profile, children }: ArchiveProps) {
  const [archiveGrp, setArchive] = useState<boolean>(false);

  const archiveGroup = async () => {
    if (!group || !profile) {
      return;
    }

    if (archiveGrp && profile) {
      const newUpdateLog: DocUpdate = {
        date: new Date(),
        status: DocStatuses.Archived,
        description: `${profile.name} archived group: ${group.name}.`,
        actor: liteProfile(profile),
      };
      console.log({ newUpdateLog });
      await updateDoc(group.ref, appendDocUpdate(newUpdateLog, group));
    }
  };

  return (
    <>
      <Box sx={{ display: "inline-block" }} onClick={() => setArchive(true)}>
        {children}
      </Box>

      {archiveGrp && (
        <ConfirmDialog
          isOpen={archiveGrp}
          close={() => setArchive(false)}
          confirmTitle={`Are you sure you want to archive the "${group.name}" group?`}
          confirmButtonTitle={`Archive "${group.name}"`}
          proceedWithAction={() => archiveGroup()}
          onSuccess={() => {
            createAlert({ message: `"${group.name}" group archived.` });
            setArchive(false);
          }}
        />
      )}
    </>
  );
}
