import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import { Post, PostComment } from "@converge-collective/common/models/Post";
import {
  LiteProfile,
  ProfileWithTimestamp,
  liteProfile,
} from "@converge-collective/common/models/Profile";
import { formatDateTime } from "@converge-collective/common/util";
import {
  CancelOutlined,
  CheckCircleOutline,
  EditOutlined,
  QuestionAnswer,
  ReplyOutlined,
  ThumbUpAlt,
  ThumbUpAltOutlined,
} from "@mui/icons-material";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import React, { useRef, useState } from "react";
import { GrAddCircle, GrSubtractCircle } from "react-icons/gr";
import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";
import useProfile, {
  useHydratedCurrentNetworkMembership,
} from "~/hooks/useProfile";
import { converters } from "~/lib/converter";
import { CreateOrEditDocumentDialog } from "../admin/CreateOrEditDocumentDialog";
import Editor from "../editor/Editor";
import EditableField, { FieldTypes } from "../forms/EditableField";
import { RenderHTML } from "../RenderHTML";
import ProfilePreview from "../user/ProfilePreview";
import UserAvatar from "../user/UserAvatar";
import { VisibleForTime } from "../VisibleForTime";
import { isEmpty } from "lodash";
import { useIsMobile } from "~/hooks/mobile";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { SeenByReadBy } from "../SeenByReadBy";
import { KeyedMutator } from "swr";
import { isHtmlContentEmpty } from "~/lib/extractors/htmlContent";
import { ViewStatsCapture } from "../ViewStatsCapture";

const SEEN_TIME = 2000;
const maxInitialComments = 2;

export function PostCommentComponent({
  shouldFocusEditor = false,
  totalCommentCount,
  mutateTotalCommentCount,
  network,
  post,
  allowPostComments = true,
  allowLikePosts = true,
}: {
  post: WithRef<Post>;
  network: WithRef<Network>;
  shouldFocusEditor?: boolean;
  totalCommentCount: number;
  mutateTotalCommentCount: KeyedMutator<number>;
  allowPostComments?: boolean;
  allowLikePosts?: boolean;
}) {
  const commentsRef = collection(post.ref, "comments").withConverter(
    converters.postComment.write
  );

  // only show the most recent two comments by default
  const [commentsLimit, setCommentsLimit] = useState<number | undefined>(
    maxInitialComments
  );

  const { data: comments = [] } = useFirestoreCollectionData(
    query(
      commentsRef,
      // use a max of 100 comments for now. if we need to go beyond this we
      // should look into infinite scrolling or similar
      limit(commentsLimit !== undefined ? commentsLimit : 100),
      orderBy("date", "desc")
    ).withConverter(converters.postComment.read)
  );

  return (
    <>
      <Stack mb={2}>
        {totalCommentCount > maxInitialComments &&
          commentsLimit !== undefined && (
            <Button
              variant="text"
              sx={{ mb: 1 }}
              onClick={() => setCommentsLimit(undefined)}
            >
              Load all {totalCommentCount} comments
            </Button>
          )}
        {comments.toReversed().map((comment) => (
          <Stack mt={0.2} width={"100%"} key={comment.id}>
            <CommentBox
              allowLikePosts={allowLikePosts}
              allowPostComments={allowPostComments}
              comment={comment}
              network={network}
              post={post}
            />
          </Stack>
        ))}
      </Stack>

      {allowPostComments && (
        <PostCommentForm
          mutateTotalCommentCount={mutateTotalCommentCount}
          shouldFocusEditor={shouldFocusEditor}
          post={post}
        />
      )}
    </>
  );
}

const flex = {
  display: "flex",
  alignItems: "center",
};

type CommentBoxProps = {
  comment: WithRef<PostComment>;
  network: WithRef<Network>;
  post: WithRef<Post>;
  allowLikePosts?: boolean;
  allowPostComments?: boolean;
};
function CommentBox({
  comment,
  network,
  post,
  allowLikePosts = true,
  allowPostComments = true,
}: CommentBoxProps) {
  const { profile } = useLoggedInState();
  const isMobile = useIsMobile();

  // Comment States
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [openQA, setOpenQA] = useState(false);
  const [addReply, setAddReply] = useState(false);
  const [inEditMode, setEditMode] = useState(false);
  const [editValue, setEditValue] = useState(comment.body);

  // Comment Props
  const isLiked = !!comment.props?.find(
    (prop) => prop.profile?.id === profile?.id
  );
  const likeCount = comment.props ? comment.props.length : 0;
  const isCommentOwner = comment.profile.id === profile?.id;

  // Replies of comment
  const repliesRef = collection(comment.ref, "replies").withConverter(
    converters.postComment.write
  );

  const { data: replies, status: repliesStatus } = useFirestoreCollectionData(
    query(repliesRef, orderBy("date", "asc")).withConverter(
      converters.postComment.read
    )
  );

  const networkMembership = useHydratedCurrentNetworkMembership();
  const shouldTrack =
    networkMembership?.network.id === network.id &&
    networkMembership.role !== "superadmin";

  // Handle Comment Props
  const handleCommentProps = async () => {
    try {
      if (!profile) return;

      // if the user already gave props, remove them, otherwise add new props
      const props = comment.props || [];
      const newProps = props.find((p) => p.profile.id === profile.id)
        ? props.filter((p) => p.profile.id !== profile.id)
        : [
            ...props,
            {
              date: new Date(),
              profile: liteProfile(profile),
            },
          ];

      await updateDoc(comment.ref, { props: newProps });
    } catch (e) {
      console.log(e);
    }
  };

  // Delete Comment Method
  const deleteComment = async (): Promise<void> => {
    await deleteDoc(comment.ref);
  };

  // On Edit Complete
  const onSaveCahnges = async (comment: WithRef<PostComment>) => {
    if (!inEditMode) return;
    await updateDoc(comment.ref, { body: editValue });
    setEditMode(false);
    setEditValue("");
  };

  const [shouldFocusEditor, setShouldFocusEditor] = useState(false);

  // On Cancel Reply
  const toggleReply = () => {
    setShouldFocusEditor(true);
    setIsCollapsed(false);
    setAddReply((p) => !p);
  };

  // Delete Action
  const DeleteAction = (
    <Tooltip title="Delete Comment">
      <IconButton
        disableRipple
        sx={{ py: 0, mt: -0, display: "flex", alignItems: "center" }}
        color="error"
        size="small"
        onClick={deleteComment}
      >
        <DeleteOutlined fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );

  // Save For QA Action
  const SaveForQA = (
    <Tooltip title="Save as Q&A">
      <IconButton
        color="primary"
        size="small"
        sx={{ py: 0, mb: "-1px", pr: 0.2 }}
        onClick={() => setOpenQA((p) => !p)}
      >
        <QuestionAnswer fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );

  // Liked Button
  const LikedAction = allowLikePosts ? (
    <Tooltip title={`${isLiked ? "Remove props" : "Give props"}`}>
      <IconButton
        disableRipple
        color="primary"
        size="small"
        onClick={handleCommentProps}
        sx={{ py: 0, display: "flex", alignItems: "center" }}
      >
        {isLiked ? (
          <ThumbUpAlt fontSize="inherit" />
        ) : (
          <ThumbUpAltOutlined fontSize="inherit" />
        )}
        <Typography sx={{ ml: 0.4 }} variant="caption" lineHeight={2}>
          {likeCount}
        </Typography>
        <Typography
          variant="caption"
          sx={{ ml: 0.3 }}
          lineHeight={2}
        ></Typography>
      </IconButton>
    </Tooltip>
  ) : null;

  // Edit Action
  const EditComment = !inEditMode ? (
    <Tooltip title={"Edit Comment"}>
      <IconButton
        size="small"
        disableRipple
        color="primary"
        onClick={() => {
          setEditMode((p) => !p);
          setEditValue(comment.body);
        }}
        sx={{ py: 0, display: "flex", alignItems: "center" }}
      >
        <EditOutlined fontSize="inherit" />
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title={"Cancel Comment"}>
      <IconButton
        size="small"
        disableRipple
        color="warning"
        onClick={() => {
          setEditMode((p) => !p);
          setEditValue("");
        }}
        sx={{ py: 0, display: "flex", alignItems: "center" }}
      >
        <CancelOutlined fontSize="inherit" />
        <Typography variant="caption" sx={{ ml: 0.4 }} lineHeight={2}>
          Cancel
        </Typography>
      </IconButton>
    </Tooltip>
  );

  // Save Edit Action
  const SaveEdit = (
    <Tooltip title={"Save Comment"}>
      <IconButton
        disableRipple
        sx={{ py: 0, display: "flex", alignItems: "center" }}
        color="primary"
        size="small"
        onClick={() => onSaveCahnges(comment)}
      >
        <CheckCircleOutline fontSize="inherit" />
        <Typography variant="caption" sx={{ ml: 0.3 }} lineHeight={2}>
          Save
        </Typography>
      </IconButton>
    </Tooltip>
  );

  // Reply Action
  const ReplyAction = allowPostComments ? (
    <Tooltip title="Reply">
      <IconButton
        disableRipple
        color={addReply ? "warning" : "primary"}
        size="small"
        onClick={toggleReply}
        sx={{ px: 0, py: 0, display: "flex", alignItems: "center" }}
      >
        <ReplyOutlined fontSize="inherit" />
        {isMobile ? null : (
          <Typography
            variant="caption"
            lineHeight={2}
            sx={{ pt: 0.1, ml: 0.3 }}
          >
            {addReply ? "Cancel" : "Reply"}
          </Typography>
        )}
      </IconButton>
    </Tooltip>
  ) : null;

  // Collapse Action
  const CollapseIcon = (
    <IconButton
      size="small"
      onClick={() => {
        setAddReply(false);
        setIsCollapsed((p) => !p);
      }}
      sx={{ py: 0, ml: 0.2 }}
    >
      {isCollapsed ? (
        <GrAddCircle color="gray" />
      ) : (
        <GrSubtractCircle color="gray" />
      )}
    </IconButton>
  );

  const wasSeenByUser = profile && comment.seenBy?.[profile.id];
  const markAsSeen = async () => {
    if (!profile) {
      console.error("Could not find profile", { post, profile });
      return;
    }

    if (!wasSeenByUser) {
      const tp: ProfileWithTimestamp = {
        profile: liteProfile(profile),
        timestamp: new Date(),
      };

      await updateDoc(comment.ref.withConverter(converters.postComment.write), {
        [`seenBy.${profile.id}`]: tp,
        [`readBy.${profile.id}`]: tp,
      });
    } else {
      // console.log(`comment all ready seen - ${post.title}`);
    }
  };

  if (!profile) return null;

  return (
    <ViewStatsCapture entity={comment.ref} url={post.url}>
      {/* Profile and Date */}
      <Stack direction={"row"} alignItems={"center"}>
        {/* Profile Sidebar */}
        <ProfilePreview
          profile={comment.profile}
          wrapperStyle={{ ...flex, cursor: "pointer" }}
        >
          <UserAvatar
            profile={comment.profile}
            sx={{ height: 28, width: 28 }}
          />
          <Typography fontWeight={"bold"} variant="subtitle2" ml={1}>
            {comment.profile.name}
          </Typography>
        </ProfilePreview>

        {/* Date Of Posting */}
        <Typography sx={{ color: "gray" }} variant="caption" ml={1}>
          {formatDateTime(comment.date)}
        </Typography>
      </Stack>

      {/* Comment Body */}
      <Box ml={1.8} pl={2.2} borderLeft={1} sx={{ borderColor: "lightgrey" }}>
        <VisibleForTime
          durationVisible={SEEN_TIME}
          onVisibleForTime={markAsSeen}
          active={shouldTrack}
        >
          <EditableField
            label="Description"
            isEditing={inEditMode}
            fieldType={FieldTypes.Html}
            minimalControls={true}
            multiline={true}
            value={editValue}
            onChange={(value: string) => setEditValue(value)}
            displayValue={
              comment.body ? <RenderHTML html={comment.body} /> : undefined
            }
          />
        </VisibleForTime>
      </Box>

      {/* Actions */}
      <Stack
        useFlexGap
        flexWrap={"wrap"}
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
        rowGap={0.5}
      >
        <Stack
          sx={{ mt: 0.1 }}
          alignItems="center"
          flexWrap={"wrap"}
          spacing={isMobile ? 0.5 : 1}
          rowGap={0.5}
          justifySelf={"flex-end"}
          direction="row"
        >
          {!!replies?.length ? CollapseIcon : <Box sx={{ py: 0, ml: 0.2 }} />}
          {LikedAction}
          {ReplyAction}
          {SaveForQA}
          {/* force the viewby readby box to the right */}
          <div>&nbsp;</div>
          {/** on mobile force readby / seenby to the next line */}
          {/* {isMobile && <Box sx={{ width: "100%" }} />} */}
          {!isMobile && (
            <SeenByReadBy
              id={comment.id}
              name={`Comment ${comment.profile.name}`}
              sx={{
                flexWrap: "wrap",
                // if we re-enable this on mobile we should add this back
                // ...(isMobile && { my: 2 }),
              }}
              isAdminOnly={false}
              seenBy={comment?.seenBy}
              readBy={comment?.readBy}
            />
          )}
        </Stack>

        {isCommentOwner && (
          <Stack
            justifySelf={"flex-end"}
            justifyContent="flex-end"
            direction="row"
            alignItems="center"
            columnGap={1}
          >
            {inEditMode && SaveEdit}
            {EditComment}
            {DeleteAction}
          </Stack>
        )}
      </Stack>

      {/* Collapsible Replies */}
      {!isCollapsed && (
        <Collapse in={true} sx={{ mt: 0.5 }}>
          {repliesStatus == "loading" ? (
            <CircularProgress size={16} />
          ) : (
            <>
              {replies && replies.length ? (
                <Stack rowGap={0.2} ml={4} pt={1}>
                  {replies.map((reply) => (
                    <CommentBox
                      allowPostComments={allowPostComments}
                      allowLikePosts={allowLikePosts}
                      comment={reply}
                      network={network}
                      post={post}
                      key={reply.id}
                    />
                  ))}
                </Stack>
              ) : null}

              {addReply && (
                <PostCommentForm
                  shouldFocusEditor={shouldFocusEditor}
                  post={post}
                  comment={comment}
                  onCancel={toggleReply}
                />
              )}
            </>
          )}
        </Collapse>
      )}

      {/* QA Modal */}
      <CreateOrEditDocumentDialog
        network={network}
        isOpen={openQA}
        isQAndA={true}
        onClose={() => setOpenQA(false)}
        initBody={comment.body}
      />
    </ViewStatsCapture>
  );
}

// Comment Form
type CommentFormProps = {
  post: WithRef<Post>;
  shouldFocusEditor: boolean;
  comment?: WithRef<PostComment>;
  onCancel?: () => void;
  mutateTotalCommentCount?: KeyedMutator<number>;
};
function PostCommentForm({
  shouldFocusEditor,
  post,
  comment,
  onCancel,
  mutateTotalCommentCount,
}: CommentFormProps) {
  const firestore = useFirestore();
  const { data: user } = useUser();

  const [profile] = useProfile(user?.uid);
  const [body, setBody] = React.useState("");
  const [validationError, setValidationError] = useState(false);

  // Commments Collection
  const commentsRef = collection(post.ref, "comments").withConverter(
    converters.postComment.write
  );

  // Post a comment
  const postComment = async () => {
    if (!profile) {
      console.error(`Can't post comment without a profile: ${profile}`);
      return;
    }

    // Subscribe to post if not already subscribed before posting a comment
    const mentionIds = getMentionsFromHtml(body);
    const subscribers =
      mentionIds?.length > 0 ? [...mentionIds, profile.id] : [profile.id];
    await subscribePostIfNot(subscribers);

    setBody("");
    // when a user comments on a post they are automatically subscribed
    await addDoc(commentsRef, {
      profile: liteProfile(profile),
      date: new Date(),
      body,
    });
    mutateTotalCommentCount && (await mutateTotalCommentCount());
  };

  const subscribePostIfNot = async (uids: string[]) => {
    const subsBatch = writeBatch(firestore);
    const cols = await getDocs(collection(post.ref, "subscriptions"));

    uids.forEach((uid) => {
      const document = doc(post.ref, "subscriptions", uid);
      const alreadyExists =
        cols.docs.find((doc) => doc.id === uid) !== undefined;

      if (!alreadyExists) {
        subsBatch.set(document, { uid });
      }
    });
    await subsBatch.commit();
    console.log("subscribed to post", uids);
  };

  const getMentionsFromHtml = (html: string) => {
    // Create a temporary div element
    const tempDiv = document.createElement("div");

    // Set the innerHTML of the div to the HTML string
    tempDiv.innerHTML = html;

    // Use querySelectorAll to get all elements with the data-id attribute
    const elementsWithDataId = tempDiv.querySelectorAll("[data-id]");

    // Loop through the selected elements and log their data-id attribute
    const mentions: string[] = [];
    elementsWithDataId.forEach((element) =>
      mentions.push(element.getAttribute("data-id") as string)
    );
    return mentions;
  };

  // Post reply
  const postReply = async () => {
    if (!profile) {
      console.error(`Can't post comment without a profile: ${profile}`);
      return;
    }

    const repliesRef = collection(comment!.ref, "replies").withConverter(
      converters.postComment.write
    );

    if (profile) {
      setBody("");
      await addDoc(repliesRef, {
        profile: liteProfile(profile),
        date: new Date(),
        body: body,
      });
    }

    const mentionIds = getMentionsFromHtml(body);
    const subscribers =
      mentionIds?.length > 0 ? [...mentionIds, profile.id] : [profile.id];
    await subscribePostIfNot(subscribers);
  };

  const sendMessage = () => {
    if (isHtmlContentEmpty(body)) {
      setValidationError(true);
      return;
    }
    setValidationError(false);
    if (comment) {
      postReply();
      onCancel?.();
    } else {
      body && postComment();
    }
  };
  if (!profile) return null;
  return (
    <Box
      onKeyDown={(event) => {
        if (event.key === "Enter" && (event.altKey || event.metaKey)) {
          sendMessage();
        }
      }}
    >
      <ReplyBox
        shouldFocusEditor={shouldFocusEditor}
        profile={profile}
        comment={comment}
        body={body}
        setBody={setBody}
        setValidationError={setValidationError}
        validationError={validationError}
      />

      <Stack sx={{ mt: 2, flexShrink: 0 }}></Stack>

      <Stack sx={{ mt: 1 }} direction="row" justifyContent="flex-end" gap={1.5}>
        {validationError && (
          <Alert severity="error">Please enter a comment.</Alert>
        )}
        {comment && onCancel ? (
          <Button size="small" onClick={onCancel}>
            Cancel
          </Button>
        ) : null}
        <Button
          disabled={isEmpty(body)}
          size="small"
          onClick={sendMessage}
          variant="contained"
        >
          {!!comment ? `Reply` : "Comment"}
        </Button>
      </Stack>
    </Box>
  );
}

function ReplyBox({
  shouldFocusEditor,
  profile,
  comment,
  body,
  setBody,
  setValidationError,
  validationError,
}: {
  shouldFocusEditor: boolean;
  profile: LiteProfile;
  comment?: WithRef<PostComment>;
  body: string;
  setBody: (body: string) => void;
  setValidationError: (error: boolean) => void;
  validationError: boolean;
}) {
  const editorRef = useRef<HTMLInputElement>(null);

  // NOTE: disabled this scroll behavior as this results in unexpectedly
  // scrolling down a feed (e.g. events page) to scroll the reply input into
  // view
  //
  // useEffect(() => {
  //   if (editorRef) {
  //     // Have to use setimeout bcz of the loading state
  //     // which causes delay of few ms
  //     setTimeout(() => {
  //       editorRef.current?.scrollIntoView({
  //         behavior: "smooth",
  //         block: "center",
  //       });
  //     }, 500);
  //   }
  // }, [editorRef]);

  return (
    <>
      <Stack
        ref={editorRef}
        direction={"row"}
        columnGap={1}
        mt={1}
        sx={comment ? { ml: 4 } : {}}
      >
        <UserAvatar profile={profile} sx={{ height: 24, width: 24 }} />
        <Editor
          autofocus={shouldFocusEditor}
          minimalControls
          initialHtml={body}
          onChange={(value) => {
            setBody(value);
            if (validationError) {
              setValidationError(false);
            }
          }}
          placeholder="Add text here"
          sx={{ marginTop: 0 }}
        />
      </Stack>
      <Typography component="div" textAlign="right" variant="caption">
        <b>Cmd+Enter</b> or <b>Alt+Enter</b> to post
      </Typography>
    </>
  );
}
