import { WithRef } from "@converge-collective/common/models/Base";
import {
  ChallengeV2,
  CourseChallenge,
} from "@converge-collective/common/models/ChallengeV2";
import { Network } from "@converge-collective/common/models/Network";
import { Box, Skeleton } from "@mui/material";
import { getDoc } from "firebase/firestore";
import { converters } from "~/lib/converter";
import { ChallengeV2Card } from "../challenges/ChallengeV2Card";
import { VisibleForTime } from "../VisibleForTime";
import { CourseCard } from "../courses/CourseCard";
import { useState } from "react";

export function LazyChallengeV2Card({
  challengeV2: partialChallengeV2,
  network,
}: {
  challengeV2: Pick<WithRef<ChallengeV2>, "ref">;
  network: WithRef<Network>;
}): React.ReactElement {
  const [challengeV2, setChallengeV2] = useState<WithRef<CourseChallenge>>();

  const loadChallengeV2 = async () => {
    if (!challengeV2) {
      const fullChallengeV2 = await getDoc(
        partialChallengeV2.ref.withConverter(converters.courseChallenge.read)
      );
      setChallengeV2(fullChallengeV2.data());
    }
  };

  return (
    <VisibleForTime
      durationVisible={100}
      onVisibleForTime={() => {
        loadChallengeV2();
      }}
      sx={{ width: "100%" }}
    >
      {/* TODO - use a generic challenge card instead of the Course card */}
      {challengeV2 ? (
        <CourseCard course={challengeV2} />
      ) : (
        <Box>
          <Skeleton width="100%" variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton width="100%" variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton width="100%" variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton
            sx={{ mt: 1 }}
            width="100%"
            variant="rounded"
            height={160}
          />
        </Box>
      )}
    </VisibleForTime>
  );
}
