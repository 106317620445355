import {
  ActivityLogKinds,
  CategoryActivityLog,
  computePointsDescription,
} from "@converge-collective/common/models/ActivityLog";
import { ActivityPost } from "@converge-collective/common/models/Post";
import { formatNumber } from "@converge-collective/common/util";
import StarsIcon from "@mui/icons-material/Stars";
import { Chip, Stack, Tooltip } from "@mui/material";
import React from "react";

export default function FeedResult({
  post,
}: {
  post: ActivityPost;
}): React.ReactElement {
  // const isGoalKind = post.activityLog.kind === ActivityLogKinds.GoalActivityLog;
  const isCategoryKind =
    post.activityLog.kind === ActivityLogKinds.CategoryActivityLog;
  const categoryActivityLog = post.activityLog as CategoryActivityLog;
  // const goalActivityLog = post.activityLog as GoalActivityLog;

  return (
    <Stack
      sx={{ flexWrap: "wrap", "& .MuiChip-root": { my: 1 } }}
      justifyContent="center"
      direction="row"
      spacing={1}
    >
      {
        <>
          {isCategoryKind && (
            <Chip
              size="small"
              color="primary"
              variant="outlined"
              label={`${categoryActivityLog.duration} minutes`}
            />
          )}
          {post.points !== undefined && (
            <Tooltip title={computePointsDescription(post.activityLog)}>
              <Chip
                icon={<StarsIcon />}
                size="small"
                color="primary"
                variant="outlined"
                label={`${formatNumber(post.points)} points`}
              />
            </Tooltip>
          )}

          {isCategoryKind && categoryActivityLog.extraMultiplier && (
            <Chip
              size="small"
              color="secondary"
              variant="outlined"
              label={`${categoryActivityLog.extraMultiplier}x!`}
            />
          )}
          {isCategoryKind && (
            <Chip
              size="small"
              color="primary"
              variant="outlined"
              label={categoryActivityLog.categoryName}
            />
          )}
        </>
      }
    </Stack>
  );
}
