import {
  DocumentReference,
  collection,
  getCountFromServer,
  limit,
  orderBy,
  query,
  where,
  doc,
} from "firebase/firestore";
import { useState } from "react";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
} from "reactfire";
import { converters } from "~/lib/converter";
import { useIsMobile } from "./mobile";
import { DocStatuses } from "@converge-collective/common/models/DocMeta";
import { useQuery } from "@tanstack/react-query";

const getPostsCount = async (
  postsParent: DocumentReference,
  sticky = false
) => {
  const postsQuery = query(
    collection(postsParent, "posts"),
    ...(!sticky
      ? [where("sticky", "==", false)]
      : [where("sticky", "==", true)]),
    where("latestDocUpdate.status", "==", DocStatuses.Active),
    orderBy("date", "desc")
  );
  const postsCountResult = await getCountFromServer(postsQuery);
  return postsCountResult.data().count;
};

export function useCombinedPosts(
  postsParent: DocumentReference,
  isEnabled: boolean
) {
  const isMobile = useIsMobile();
  const pageSize = isMobile ? 20 : 20;

  // Non sticky posts count - We will manually add count of sticky posts to this
  const { data: totalPostsCount } = useQuery({
    queryKey: ["postsCount_nonSticky", postsParent.id],
    queryFn: async () => await getPostsCount(postsParent),
    enabled: isEnabled,
    throwOnError: true,
  });

  // Total Posts and Last Document
  const [pageNum, setPageNum] = useState<number>(1);

  // Base for posts
  const baseQuery = query(collection(postsParent, "posts"));

  // Get queries for sticky and non sticky posts
  const getNonStickyPostsQuery = query(
    baseQuery,
    where("sticky", "==", false),
    // can't filter by sticky != true or it will filter out non-MessagePost
    // posts which don't have a value set for `sticky`, so we filter them out
    // client-side instead. TODO start filtering them and make sure all posts
    // have a value set for `sticky`
    orderBy("date", "desc"),
    // enable this when we have latestDocUpdate.status on all posts
    // NOTE: there might be posts without this set so until 2025/01/11 we did
    // the filtering client-side. we'll need to check for these and update the
    // code to ensure it exists.
    // we removed client side filtering because if a user deleted the entire
    // first page the newsfeed would be stuck in a loading state.
    where("latestDocUpdate.status", "!=", DocStatuses.Deleted),
    // fetch nothing if not enabled (can't set to 0 so use 1)
    limit(isEnabled ? pageNum * pageSize : 1)
  ).withConverter(converters.post.read);

  const getStickyPostsQuery = query(
    baseQuery,
    where("sticky", "==", true),
    orderBy("date", "desc")
  ).withConverter(converters.messagePost.read);

  // Get posts - sticky and non sticky
  const { data: nonStickyPosts = [] } = useFirestoreCollectionData(
    getNonStickyPostsQuery
  );
  const { data: stickyPostsRaw = [] } =
    useFirestoreCollectionData(getStickyPostsQuery);
  const stickyPosts = stickyPostsRaw.filter(
    (p) => p.stickyExpireDate && p.stickyExpireDate > new Date()
  );

  // Grouping sticky and non sticky posts together
  const combinedPosts = [...stickyPosts, ...nonStickyPosts];
  const combinedTotalPostsCount = totalPostsCount
    ? totalPostsCount + stickyPosts.length
    : undefined;
  const hasMorePosts =
    typeof combinedTotalPostsCount === "number" &&
    combinedPosts.length < combinedTotalPostsCount;

  return {
    posts: combinedPosts,
    fetchPosts: () => setPageNum((p) => p + 1),
    hasMore: hasMorePosts,
  };
}

export function useStickyOnlyPosts(
  postsParent: DocumentReference,
  isEnabled: boolean
) {
  const isMobile = useIsMobile();
  const pageSize = isMobile ? 20 : 20;

  // Non sticky posts count - We will manually add count of sticky posts to this
  const { data: totalPostsCount } = useQuery({
    queryKey: ["stickyPostsCount", postsParent.id],
    queryFn: async () => await getPostsCount(postsParent, true),
    enabled: isEnabled,
    throwOnError: true,
  });

  // Total Posts and Last Document
  const [pageNum, setPageNum] = useState<number>(1);

  // Base for posts
  const baseQuery = query(collection(postsParent, "posts"));

  // Get queries for sticky posts
  const getStickyPostsQuery = query(
    baseQuery,
    where("sticky", "==", true),
    orderBy("date", "desc"),
    limit(isEnabled ? pageNum * pageSize : 1)
  ).withConverter(converters.messagePost.read);

  // Get posts - sticky
  const { data: stickyPosts = [] } =
    useFirestoreCollectionData(getStickyPostsQuery);

  const hasMorePosts =
    typeof totalPostsCount === "number" && stickyPosts.length < totalPostsCount;

  return {
    posts: stickyPosts,
    fetchPosts: () => setPageNum((p) => p + 1),
    hasMore: hasMorePosts,
  };
}

export function useSinglePost(postsParent: DocumentReference, postId?: string) {
  const firestore = useFirestore();
  const baseQuery = collection(postsParent, "posts");

  const postQuery = (
    postId ? doc(baseQuery, postId) : doc(firestore, "noop/noop")
  ).withConverter(converters.messagePost.read);

  const { data: postForId, status } = useFirestoreDocData(postQuery);
  return {
    post: postForId,
    loading: status === "loading",
    hasPost: postForId && postForId.id,
    isDeleted:
      postForId && postForId.latestDocUpdate?.status === DocStatuses.Deleted,
  };
}
