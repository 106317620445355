import { liteNetwork } from "@converge-collective/common/models/Network";
import { nanoProfile } from "@converge-collective/common/models/Profile";
import {
  StatKind,
  StatsKinds,
  ViewStatItem,
  ViewStats,
} from "@converge-collective/common/models/ViewStats";
import { Box, SxProps } from "@mui/material";
import {
  DocumentReference,
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { useState } from "react";
import { useHydratedCurrentNetworkMembership } from "~/hooks/useProfile";
import { converters } from "~/lib/converter";
import { sha256Hex } from "~/lib/hash";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { VisibleForTime } from "./VisibleForTime";

const ReadTime = 1500;
const SeenTime = 300;

export async function documentViewStatId(entity: DocumentReference) {
  return sha256Hex(entity.path);
}

// .../{network}/viewStats/{viewStatId}/viewStatItemsUnique/{hash(uid + kind)}
// then we can do unique count aggregates like:
// where("profile.id", "==", uid)
// where("kind", "==", "read")
//
// with optional date windows like last 6 months:
// where("createdAt", ">", addMonths(new Date(), -6))
export async function uniqueViewStatItemId(uid: string, statKind: StatKind) {
  return sha256Hex(`${uid}-${statKind}`);
}

/**
 * unlike previous versions, read by and seen bys are not unique per user.
 *
 * Now, the only requirement is that the user left and came back in order to
 * re-trigger a new read/seen by, so keep track of route changes as well.
 */
export function ViewStatsCapture({
  url,
  entity,
  children,
  sx,
}: {
  /** if unspecified it uses the current browser url */
  url?: string;
  entity: DocumentReference;
  children: React.ReactNode;
  sx?: SxProps;
}) {
  const { network, profile } = useLoggedInState();

  const networkMembership = useHydratedCurrentNetworkMembership();
  // disable tracking for superadmins
  const shouldTrack =
    network &&
    networkMembership?.network.id === network.id &&
    networkMembership.role !== "superadmin";

  // keep track of whether it was markec as read/seen so that we don't capture
  // more than once (until this component is unmounted and re-mounted)
  const [wasMarked, setWasMarked] = useState({
    seen: false,
    read: false,
  });

  /**
   * add the child view stat item while ensuring the parent ViewStats doc
   * exists for the provided entity
   */
  async function addViewStat(viewStatItem: ViewStatItem) {
    if (!shouldTrack) {
      console.info("skipping view stats capture", {
        networkMembership,
        shouldTrack,
      });
      return;
    }

    if (!network) {
      console.error("no network", { network });
      return;
    }
    // creates top level viewStats if it doesn't exist
    const viewStatsId = await documentViewStatId(entity);
    const viewStatsRef = doc(
      network.ref,
      "viewStats",
      viewStatsId
    ).withConverter(converters.viewStats.read);
    const viewStats = await getDoc(viewStatsRef);
    if (!viewStats.exists()) {
      console.log(`creating new ViewStats doc for entity ${entity.path}`, {
        entity,
      });
      const viewStats: ViewStats = {
        createdAt: new Date(),
        url: url || location.href,
        network: liteNetwork(network),
        entity,
        // empty placeholders, these are computed in a background fn
        // every time a child ViewStatItem is added
        seenProfiles: [],
        viewedProfiles: [],
        uniqueReads: 0,
        totalReads: 0,
        uniqueSeens: 0,
        totalSeens: 0,
      };
      await setDoc(
        viewStatsRef.withConverter(converters.viewStats.write),
        viewStats
      );
    }
    console.log("adding child viewStat item", { viewStatItem, viewStatsRef });
    await addDoc(
      collection(viewStatsRef, "viewStatItems").withConverter(
        converters.viewStatItem.write
      ),
      viewStatItem
    );
    // also ensure the unique view stat is set. if it already exists we don't
    // want to overwrite it. that way we can show uniques over time, based on
    // the created at.
    const uniqueVSIId = await uniqueViewStatItemId(
      viewStatItem.profile.id,
      viewStatItem.kind
    );
    const uniqueViewStatItemRef = doc(
      collection(viewStatsRef, "viewStatItemsUnique").withConverter(
        converters.viewStatItem.write
      ),
      uniqueVSIId
    );
    if (!(await getDoc(uniqueViewStatItemRef)).exists()) {
      console.log(`creating unique viewStatItem ${uniqueViewStatItemRef.id}`, {
        uniqueViewStatItemRef,
        viewStatItem,
      });
      setDoc(
        uniqueViewStatItemRef.withConverter(converters.viewStatItem.write),
        viewStatItem
      );
    }
  }

  async function markAsSeen() {
    if (!network) {
      console.error("can't markAsSeen, no network", { network });
      return;
    }
    if (!profile) {
      console.error("can't markAsSeen, no profile", { profile });
      return;
    }
    if (!wasMarked.seen) {
      console.log(`marking as seen ${entity.id}`, {
        wasMarked,
        entity,
        profile,
        network,
      });
      const viewStat: ViewStatItem = {
        createdAt: new Date(),
        kind: StatsKinds.seen,
        profile: nanoProfile(profile),
        network: liteNetwork(network),
        entity,
      };
      await addViewStat(viewStat);
      setWasMarked({ ...wasMarked, seen: true });
    } else {
      console.log(`skipping marking as seen ${entity.id}`, {
        wasMarked,
        entity,
        profile: nanoProfile(profile),
        network: liteNetwork(network),
      });
    }
  }

  async function markAsRead() {
    if (!profile) {
      console.error("can't markAsRead, no profile", { profile });
      return;
    }
    if (!network) {
      console.error("can't markAsRead, no network", { network });
      return;
    }
    if (!wasMarked.read) {
      console.log(`marking as read ${entity.id}`, {
        wasMarked,
        profile,
        entity,
        network,
      });
      const viewStat: ViewStatItem = {
        createdAt: new Date(),
        kind: StatsKinds.read,
        profile: nanoProfile(profile),
        network: liteNetwork(network),
        entity,
      };
      await addViewStat(viewStat);
      setWasMarked({ ...wasMarked, read: true });
    }
  }

  return (
    <VisibleForTime
      sx={sx}
      durationVisible={SeenTime}
      onVisibleForTime={markAsSeen}
      active={!wasMarked.seen}
    >
      <>
        {children}

        {/* at the end of the content trigger "read" */}
        <VisibleForTime
          durationVisible={ReadTime}
          onVisibleForTime={markAsRead}
          active={!wasMarked.read}
        >
          <Box sx={{ height: 1 }} />
        </VisibleForTime>
      </>
    </VisibleForTime>
  );
}
