export const isHtmlContentEmpty = (html: string) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  // Check if there is any text content
  const hasText = tempDiv.textContent?.trim() !== "";

  // Check if there are any images, videos, or mux-videos
  const hasMedia = Array.from(
    tempDiv.querySelectorAll("img, video, mux-video")
  ).some(
    (el) =>
      (el.tagName.toLowerCase() === "img" && el.getAttribute("src")) ||
      (el.tagName.toLowerCase() === "video" && el.getAttribute("src")) ||
      (el.tagName.toLowerCase() === "mux-video" && el.getAttribute("uploadid"))
  );

  // Return true only if there's no text and no media
  return !(hasText || hasMedia);
};
