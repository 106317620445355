import { Paper, Typography } from "@mui/material";
import CenterHeadline from "./common/CenterHeadline";

export default function NotFound({
  message,
}: {
  message?: string;
}): React.ReactElement {
  return (
    <Paper sx={{ p: 4 }}>
      <CenterHeadline title="404 Not Found">
        <Typography variant="h4" gutterBottom>
          {message || "Hmm, we couldn't find anything here."}
        </Typography>
      </CenterHeadline>
      <Typography textAlign="center" sx={{ mt: 2, mb: 2 }} variant="body1">
        Please use the site navigation to continue.
      </Typography>
    </Paper>
  );
}

export function DeletedMessage({ message }: { message: string }) {
  return (
    <Paper sx={{ p: 4 }}>
      <CenterHeadline title={message} />
      <Typography textAlign="center" sx={{ mt: 2, mb: 2 }} variant="body1">
        Please use the site navigation to continue.
      </Typography>
    </Paper>
  );
}
