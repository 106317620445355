import CircleIcon from "@mui/icons-material/Circle";
import FilterHdrIcon from "@mui/icons-material/FilterHdr";
import { Divider, Link, Stack, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import NextLink from "next/link";
import React, { useEffect, useState } from "react";
import BrandLogo from "~/components/common/BrandLogo";
import { AppButtons } from "./AppStoreButtons";
import { DebugInfo } from "./DebugInfo";
import { ReactNativeBridge } from "./ReactNativeBridge";
import { ElectronBridge } from "./ElectronBridge";

declare const window: any;
declare const appzi: any;

const isDebugEnabled = process?.env?.ENABLE_DEBUG_MODE === "true";

const ConvergeFooter = styled("footer")(({ theme }) => ({
  zIndex: 100,
  position: "relative",
  marginTop: theme.spacing(1),
  padding: theme.spacing(3),
  fontSize: "0.7rem",
  maxWidth: "100%",
  overflow: "scroll",
}));

const FooterLink = styled(Link)(
  ({ theme }) => `
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
  font-size: 0.9em;
  color: #333;
  text-decoration-color: #333;
  :hover {
    text-decoration: underline;
    color: ${theme.palette.primary.main};
  }
`
);

export default function Footer(): React.ReactElement {
  const openAppzi = () => {
    appzi?.openWidget("1f9cacdd-eb38-47c5-ac58-98c38310e2ef");
    return false;
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [_debug, setDebug] = useState("init debug");

  const mb = isMobile ? 10 : 1;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setDebug(
      window?.ReactNativeWebView ? "RNWV is available" : "RNWV is not available"
    );
  });
  return (
    <ConvergeFooter suppressHydrationWarning={true} sx={{ mb }}>
      <BrandLogo />
      <Stack
        sx={{ pt: 2, fontSize: 14, flexFlow: "wrap" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        <FooterLink onClick={openAppzi}>Feedback</FooterLink>
        <CircleIcon sx={{ mx: 0.4, fontSize: 4 }} />
        <NextLink href="/privacy-policy" passHref legacyBehavior>
          <FooterLink>Privacy Policy</FooterLink>
        </NextLink>
        <CircleIcon sx={{ mx: 0.4, fontSize: 4 }} />
        <NextLink href="/security" passHref legacyBehavior>
          <FooterLink>Security</FooterLink>
        </NextLink>
        <CircleIcon sx={{ mx: 0.4, fontSize: 4 }} />
        <NextLink href="/terms" passHref legacyBehavior>
          <FooterLink>Terms of Use</FooterLink>
        </NextLink>
        <CircleIcon sx={{ mx: 0.4, fontSize: 4 }} />
        <NextLink href="/about" legacyBehavior>
          <FooterLink>About</FooterLink>
        </NextLink>
        <CircleIcon sx={{ mx: 0.4, fontSize: 4 }} />
        <NextLink href="/contact" legacyBehavior>
          <FooterLink>Contact Us</FooterLink>
        </NextLink>
        <CircleIcon sx={{ mx: 0.4, fontSize: 4 }} />
        <NextLink href="/support" legacyBehavior>
          <FooterLink>Help & Support</FooterLink>
        </NextLink>
      </Stack>
      <Stack
        sx={{ py: 0.5, color: "#aaa", fontSize: 12, flexFlow: "wrap" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        converge.is <span style={{ fontSize: ".8em" }}>&copy;</span>
        Converge Collective LLC
        <span style={{ margin: 3, lineHeight: "0em", fontSize: "1.6em" }}>
          {" "}
          <FilterHdrIcon />
        </span>
        Made in Billings, Montana
      </Stack>
      <Stack
        sx={{ py: 0.5, color: "#aaa", fontSize: 14, flexFlow: "wrap" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        <Divider sx={{ width: "100%", maxWidth: 600, m: 1 }} />
      </Stack>

      <Stack direction="row" justifyContent="center">
        {isDebugEnabled && <DebugInfo sx={{ maxWidth: "96%" }} />}
      </Stack>

      <AppButtons />

      <ReactNativeBridge />
      <ElectronBridge />
    </ConvergeFooter>
  );
}
